import React, { useState } from 'react';
import {
  Button, Checkbox,
} from 'antd';

const keys = {
  '# Industry Studies (General)': true,
  '# Industry Studies (Search Specific)': true,
  'Study Index (Search Specific)': true,
  'Competing Studies': true,
  'Alliance Member Name': true,
  'Alliance Contact': true,
  'Parexel Contact': true,
  '# PXL Studies (General)': true,
  '# PXL Studies (Search Specific)': true,
  'Date Added': false,
  'Tier (Search Specific)': true,
  'Profile Tier(General)': false,
  Specialities: false,
  'VAT Invoice Present': false,
  'Enrolled Subjects (General)': true,
  'Enrolled Subjects (Search Specific)': true,
  Quality: true,
  Reliability: true,
  'Reliability Score': true,
  'KOL Score': true,
  'Alliance Type': true,
  'Start Up Months': true,
  'Start Up Factor': true,
  'Start Up Index': true,
  'Recruitment Factor (General)': true,
  'Recruitment Factor (Search Specific)': true,
  'Recruitment Index (Search Specific)': true,
  'Last Trial Start Date': false,
  Center: false,
  City: false,
  State: false,
  Country: false,
  Networks: false,
};

const runAnalysisKeys = {
  '# Industry Studies': true,
  'Competing Studies': true,
  '# PXL Studies (General)': true,
  '# PXL Studies (Search Specific)': true,
  'Enrolled Subjects': true,
  Quality: true,
  'KOL Score': true,
  'Alliance Type': true,
  'Date Added': false,
  'Start Up Months': true,
  'Start Up Factor': true,
  'Start-up Index': true,
  'Recruitment Factor': true,
  'Last Trial Start Date': false,
  Center: false,
  City: false,
  State: false,
  Country: false,
  Networks: false,
};

const savedProjectKeys = {
  'Feasibility Status': true,
  'Feasibility Status Date': false,
  'LCOR Status': true,
  'Sponsor Status': true,
  'LCOR Status Date': false,
  'Alliance Member Name': true,
  'Date Added': false,
  'Alliance Contact': true,
  'Parexel Contact': true,
  'LCOR Comment': true,
  'Analysis ID': false,
  Priority: true,
  Origin: true,
  'Profile Tier(General)': false,
  Specialities: false,
  'VAT Invoice Present': false,
  'Originating Party': true,
  'Network Validated?': false,
  'Network Validated On': false,
  'CDA Expiry': false,
  'Query ID': false,
  '# Industry Studies (General)': true,
  '# Industry Studies (Search Specific)': true,
  'Study Index (Search Specific)': true,
  'Competing Studies': true,
  'Alliance Type': true,
  'Start Up Months': true,
  'Start Up Factor': true,
  'Start-up Index': true,
  'Recruitment Factor (General)': true,
  'Recruitment Factor (Search Specific)': true,
  'Recruitment Index (Search Specific)': true,
  '# PXL Studies (General)': false,
  '# PXL Studies (Search Specific)': false,
  'Tier (Search Specific)': true,
  'Enrolled Subjects (General)': false,
  'Enrolled Subjects (Search Specific)': false,
  Quality: false,
  Reliability: false,
  'Reliability Score': false,
  'KOL Score': false,
  Center: false,
  City: false,
  State: false,
  Country: false,
  Networks: false,
  'Last Trial Start Date': true,
}

const snippetViewPiKeys = {
  Tier: true,
  '# Industry Studies': true,
  'Competing Studies': false,
  '# PXL Studies': true,
  'Enrolled Subjects': false,
  Quality: true,
  'KOL Score': false,
  'Alliance Type': false,
  'Date Added': false,
  'Start Up Months': false,
  'Start Up Index': false,
  'Recruitment Factor': false,
  'Last Trial Start Date': false,
}
const ColumnFilters = (props) => {
  const [checkboxes, setCheckBoxes] = useState(props.pageName === 'runAnalysis' || props.pageName === 'runQueryPage' ? runAnalysisKeys : props.pageName === 'savedProjectResults' ? props.displayColumns : props.pageName === 'snippetView' ? snippetViewPiKeys : keys);
  const [selectAllColumn, setSelectAllColumn] = useState(undefined)

  const onChange = (e, box) => {
    // if (props.pageName === 'snippetView') {
    // }
    const temp = { ...checkboxes };
    temp[box] = e.target.checked;
    setCheckBoxes(temp);
  };

  const selectAll = () => {
    setSelectAllColumn(true)
    const allChecked = Object.keys(checkboxes).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
    setCheckBoxes(allChecked);
  };

  const deselectAll = () => {
    setSelectAllColumn(false)
    const allUnchecked = Object.keys(checkboxes).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    setCheckBoxes(allUnchecked);
  };

  return (
    <>
      <div className="filters">
        <div className="country-summary-btn">
          <div className="btn-div">
            <Button
              className={`${selectAllColumn === true ? 'selected select-deselect-all-btn ' : 'select-deselect-all-btn '}
              `}
              onClick={() => selectAll()}
            >
              Select All
            </Button>
          </div>
          <div className="btn-div">
            <Button
              className={`${selectAllColumn === false ? 'selected select-deselect-all-btn' : 'select-deselect-all-btn'}
              `}
              onClick={() => deselectAll()}
            >
              Deselect All
            </Button>
          </div>
        </div>
        {Object.keys(props.pageName === 'runAnalysis' || props.pageName === 'runQueryPage' ? runAnalysisKeys : props.pageName === 'savedProjectResults' ? savedProjectKeys : props.pageName === 'snippetView' ? snippetViewPiKeys : keys).map((box, idx) => {
          return (
            <Checkbox
              key={idx}
              checked={checkboxes[box]}
              onChange={(e) => onChange(e, box)}
            >
              <div>{box}</div>
            </Checkbox>
          );
        })}
      </div>
      <div className="show-hide-btns">
        <Button onClick={() => props.applyColumns(checkboxes)} type="primary">
          Apply
        </Button>
      </div>
    </>
  );
};

export default ColumnFilters;
