import { sendNotification, NOTIFICATION, cancelNotification } from './container/Notification/logic'

export default ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type.indexOf('_SUCCESS') > -1) {
    dispatch(sendNotification({
      type: action.type,
      notificationType: 'success',
      message: action.message ? action.message : (action.payload && action.payload.response && action.payload.response.message) ? action.payload.response.message : 'Successfull',
      showNotification: action.showNotification,
      status: action.payload.status,
    }))
  }
  if (action.type.indexOf('_FAILURE') > -1) {
    dispatch(sendNotification({
      type: action.type,
      notificationType: 'error',
      message: action.payload.status === 401
        ? 'Your current session has expired'
        : action.payload.status === 409 && action.type === 'ADMIN_APPROVAL_FAILURE'
          ? undefined
          : action.payload.status === 409 && action.type === 'UPLOAD_SPONSORS_FILE_FAILURE'
            ? undefined
            : action.payload.status === 409 && action.type === 'CREATE_SPONSOR_FAILURE'
              ? undefined
              : action.payload.status === 409 && action.type === 'CREATE_SPONSOR_USER_FAILURE'
                ? undefined
                : action.payload.status === 412 && action.type === 'CREATE_SPONSOR_USER_FAILURE'
                  ? undefined
                  : action.payload.status === 409 && action.type === 'ADD_PI_TO_NETWORK_FAILURE'
                    ? undefined
                    : action.payload.status === 409 && action.type === 'ADD_TO_SNIPPET_VIEW_FAILURE'
                      ? 'Investigator already exist in snippet view'
                      : action.payload.status === 409 && action.type === 'UPDATE_COUNTRY_PROFILE_DATA_FAILURE'
                        ? undefined
                        : action.payload.status === 422 && action.type === 'ADD_PI_FAILURE'
                          ? action.payload.response.data.message
                          : action.payload.status === 409 && action.type === 'CREATE_SPONSOR_FAILURE'
                            ? action.payload.response.data.message
                            : action.message
                              ? action.message
                              : (action.payload && action.payload.response && action.payload.response)
                                ? (action.payload.response.message || action.payload.response.error || 'Oops! There has been an issue. Re-try in some time.')
                                : 'Oops! There has been an issue. Re-try in some time.',
      showNotification: !action.hideNotification,
      status: action.payload.status,
    }))
  }
  if (action.type === NOTIFICATION) {
    dispatch(cancelNotification())
  }
}
