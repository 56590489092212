import React, { useState, useEffect } from 'react';
import '../../components/InvestigatorProfilePage/investigatorprofile.scss';
import {
  Collapse, Switch, Tooltip, Button, Input, Modal, Select,
  Checkbox, DatePicker,
} from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { parse, stringify } from 'query-string';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import TagsBar from '../../../common/components/tagsBar';
import NetworkPIList from './NetworkPIList';
import NetworkCenterList from './NetworkCentersList';
import SearchResultFilters from '../SearchResults/searchResultFilters';
import NetworkDetailsInfo from './NetworkDetailsInfo';
import { getNetworkDetailsAction, modifyNetworkAction, deleteNetworkAction } from './logic';
import MultiAutoSelect from '../AutoSuggest/MultiAutoSelect';
import { getCookie, notify } from '../../../utils';
import { ListUsersAction } from '../ShareProjectModal/logic';

import {
  getautosuggestionCancelAction,
  getautosuggestionResetAction,
  getautosuggestvalueAction,
} from '../AddProjectName/logic';
import Loader from '../../../common/components/Loader';

const navTabs = [
  {
    name: 'Network Details',
    key: 'networkDetails1',
    additionalClass: '',
    title: '',
  },
  {
    name: 'Investigators',
    key: 'investigators',
    additionalClass: '',
    title: '',
  },
  {
    name: 'Centers',
    key: 'centers',
    additionalClass: '',
    title: '',
  },
];

const therapyArea = [
  'Haematology and Oncology',
  'Oncology',
  'CNS',
  'Autoimmune/Inflammation',
  'Neurology and Psychiatry',
  'Cardiovascular',
  'Infectious Disease',
  'Metabolic/Endocrinology',
  'Cardiovascular Disorders',
  'Infectious Diseases',
  'Endocrine and Metabolism Disorders',
  'Pulmonary Disorders',
  'Genitourinary Disorders',
  'Gastroenterology',
  'Rheumatology and Orthopaedics',
  'Allergy, Immunology and Transplantation',
  'Genitourinary',
  'Vaccines (Infectious Disease)',
  'Ophthalmology',
  'Ophthalmology & Other Sensory Organ DSD',
  'Dermatology',
  'Other Therapeutic Areas',
  'Haematology',
  'Unassigned',
  'Neurology',
  'Psychiatry',
  'Endocrine',
  'Metabolism Disorders',
  'NA',
  'Orthopaedics',
  'Rheumatology',
  'Allergy',
  'Immunology',
  'Transplantation',
  'other Sensory Organ Disorders',
];

// const paymentStructureFilter = [
//   'Separate budget / contract with headquarter',
//   'Headquarter costs covered by site payments',
//   'Central contract as well as covering via site payments possible',
// ];
const NetworkTypeFilter = [
  'Investigator Network',
  'Center Network',
  'Investigator & Center Network',

];
// const FeasibilityProcessFilter = [
//   'Central feasibility contact at headquarter',
//   'Central feasibility contact at center',
//   'Directly to PI',
// ];

function NetworkDetails() {
  const { Panel } = Collapse;
  const history = useHistory()
  const { Option } = Select;
  const [activeTab, setActiveTab] = useState(navTabs[0].key);
  const [switchChange, setSwitchChange] = useState(false);
  const [deleteNetworkModal, setDeleteNetworkModal] = useState(false);
  const [networkMetaDetails, setNetworkMetaDetails] = useState({});
  const [networkIdToModify, setNetworkIdToModify] = useState('');
  const [piList, setPiList] = useState();
  // const type = 'investigators';
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [networkName, setNetworkName] = useState('');
  const [therapy, setTherapy] = useState([]);
  const [indications, setIndication] = useState([]);
  const [description, setDescription] = useState('');
  const [selectedUser, setSelectedUser] = useState([]);
  const [networkNameAlreadyUsed, setNetworkNameAlreadyUsed] = useState(false);
  const modifyNetworkRespose = useSelector((store) => store.modifyNetwork);
  const deleteNetworkResponse = useSelector((store) => store.deleteNetwork);
  const usersList = useSelector((state) => state.usersList);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [isValidatedModal, setIsValidatedModal] = useState(false)
  const [date, setDate] = useState(undefined)
  const [piCount, setPiCount] = useState(0)
  const [centerCount, setCenterCount] = useState(0)
  const userId = getCookie('userName')

  // const [addressOfNetwork, setAddressOfNewtork] = useState({
  //   line1: '',
  //   line2: '',
  //   city: '',
  //   state: '',
  //   zip: '',
  // });
  // const [surveyEmail, setSurveyEmail] = useState('');
  // const [surveyPersonDetails, setSurveyPersonDetails] = useState({
  //   surveyPersonName: '',
  //   surveyPersonContact: '',
  //   surveyPersonEmail: '',
  // });
  // const [legalEntity, setLegalEntity] = useState('');
  // const [paymentStructure, setPaymentStructure] = useState('');
  // const [percentCharge, setPercentCharge] = useState();
  const [networkType, setNetworkType] = useState('');
  const [prevNetworkName, setPrevNetworkName] = useState('');
  // const [centeralizedServices, setCenteralizedServices] = useState({
  //   embeddedSiteModal: '',
  //   centeralFeasibility: '',
  //   centralBudget: '',
  //   siteContracts: '',
  //   sitePaymentSupport: '',
  //   centrallyCoordinates: '',
  //   projectManagement: '',
  //   provisionOfEquipment: '',
  //   centrallyCoordinated: '',
  // });
  // const [provisionOfEquipmentDesc, setProvisionOfEquipmentDesc] = useState('');
  // const [feasibilityProcess, setFeasibilityProcess] = useState('');
  const [isValidated, setIsValidated] = useState(false)
  const [networkNameError, setNetworkNameError] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState({
    alliance: undefined,
    quality_rating: undefined,
    tier: undefined,
  });

  const networkData = useSelector((store) => store.getNetworkDetails);

  useEffect(() => {
    const network_id = parse(location.search).id;
    const from = 1;
    const size = 10;
    const filters = JSON.stringify({ parent_flag: false });

    dispatch(
      getNetworkDetailsAction({
        type: activeTab === 'investigators' || activeTab === 'networkDetails1' ? 'investigators' : 'centers',
        network_id,
        from,
        size,
        filters,
      }),
    );
    dispatch(ListUsersAction())
  }, []);

  useEffect(() => {
    if (networkName !== '' && networkName === prevNetworkName) {
      setNetworkNameAlreadyUsed(true);
    } else {
      setNetworkNameAlreadyUsed(false);
    }
  }, [networkName]);

  useEffect(() => {
    const network_id = parse(location.search).id;
    const from = 1;
    const size = 10;
    const filters = JSON.stringify({ ...appliedFilters, parent_flag: false });

    dispatch(
      getNetworkDetailsAction({
        type: activeTab === 'investigators' || activeTab === 'networkDetails1' ? 'investigators' : 'centers',
        network_id,
        from,
        size,
        filters,
      }),
    );
  }, [appliedFilters]);

  useEffect(() => {
    if (modifyNetworkRespose?.data?.statusCode === 409) {
      setPrevNetworkName(networkName);
      setNetworkNameAlreadyUsed(true);
      setEditModal(true)
    } else if (modifyNetworkRespose?.flag) {
      setIsValidatedModal(false)
      setIsValidated(false)
      setDate(undefined)
      setEditModal(false)
      setSelectedDate(undefined)
      const network_id = parse(location.search).id;
      const from = 1;
      const size = 10;
      const filters = JSON.stringify({ ...appliedFilters, parent_flag: false });

      dispatch(
        getNetworkDetailsAction({
          type: activeTab === 'investigators' || activeTab === 'networkDetails1' ? 'investigators' : 'centers',
          network_id,
          from,
          size,
          filters,
        }),
      );
    }
  }, [modifyNetworkRespose]);

  useEffect(() => {
    if (deleteNetworkResponse.flag) {
      const paramss = {
        searchfield: parse(location.search).searchfield,
        currentTab: parse(location.search).currentTab,
        query: parse(location.search).query,
      };
      history.replace(`/searchresults?${stringify(paramss)}`);
    }
  }, [JSON.stringify(deleteNetworkResponse)]);

  const getValue = () => {
    if (date !== 0 && date !== '' && date !== undefined) {
      return moment(date * 1000)
    }
    return undefined
  }

  const getFinalDate = () => {
    let finalDate = 0
    const a = new Date(date * 1000)
    finalDate = `${a.getDate()}-${a.getMonth() + 1}-${a.getFullYear()}`
    return finalDate
  }

  useEffect(() => {
    if (networkData.flag) {
      setNetworkMetaDetails(networkData.data.data.network_details);
      setNetworkName(networkData.data.data.network_details.name);
      setNetworkIdToModify(networkData.data.data.network_details.innoplexus_id);
      setIsValidated(networkData.data.data.network_details.is_network_validated)
      setDate(networkData.data.data.network_details.network_validated_on)
      setSelectedDate(networkData.data.data.network_details.network_validated_on)
      setIndication(networkData.data.data.network_details.indications);
      setTherapy(networkData.data.data.network_details.therapy_area);
      setDescription(networkData.data.data.network_details.description);
      setPiCount(networkData.data.data.network_details.pi_count);
      setCenterCount(networkData.data.data.network_details.center_count)
      setSelectedUser(networkData.data.data.network_details.network_owner_ids)
      // setAddressOfNewtork({
      //   line1: networkData.data.data.network_details.address.address_line_1,
      //   line2: networkData.data.data.network_details.address.address_line_2,
      //   city: networkData.data.data.network_details.address.city,
      //   state: networkData.data.data.network_details.address.state,
      //   zip: networkData.data.data.network_details.address.zip,
      // });
      // setLegalEntity(networkData.data.data.network_details.legal_entity);
      // setPaymentStructure(networkData.data.data.network_details.payment_structure);
      // setPercentCharge(networkData.data.data.network_details.percent_overhead_charged);
      setNetworkType(networkData.data.data.network_details.network_affiliation);
      // setSurveyEmail(networkData.data.data.network_details.survey_email);
      // setCenteralizedServices({
      //   embeddedSiteModal: networkData.data.data.network_details.network_type.embedded_site_model,
      //   centeralFeasibility: networkData.data.data.network_details.network_type.central_feasibility,
      //   centralBudget: networkData.data.data.network_details.network_type.central_budget,
      //   siteContracts: networkData.data.data.network_details.network_type.site_contracts,
      //   sitePaymentSupport: networkData.data.data.network_details.network_type.site_payment_support,
      //   centrallyCoordinates:
      //   networkData.data.data.network_details.network_type.centrally_coordinated_patient_recruitment_services,
      //   projectManagement: networkData.data.data.network_details.network_type.project_management,
      //   provisionOfEquipment: networkData.data.data.network_details.network_type.provision_of_equipment,
      // });
      // setSurveyPersonDetails({
      //   surveyPersonName: networkData.data.data.network_details.feasibility_contact_details.name,
      //   surveyPersonContact: networkData.data.data.network_details.feasibility_contact_details.phone_number,
      //   surveyPersonEmail: networkData.data.data.network_details.feasibility_contact_details.email,
      // });
      // setProvisionOfEquipmentDesc(networkData.data.data.network_details.network_type.provision_of_equipment_description);
      // setFeasibilityProcess(networkData.data.data.network_details.feasibility_process);
      setPiList(networkData.data.data.pi_summary);
    }
  }, [JSON.stringify(networkData)]);
  const onTabChange = (tab) => {
    setActiveTab(tab.key);
    setSwitchChange(false)

    if (tab.key !== activeTab) {
      const temp = {
        alliance: undefined,
        quality_rating: undefined,
        tier: undefined,
      };
      setAppliedFilters(temp);
    }
  };

  const resetFilters = () => {
    const temp = {
      alliance: undefined,
      quality_rating: undefined,
      tier: undefined,
    };
    setAppliedFilters(temp);
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'networkDetails1': {
        return <NetworkDetailsInfo />
      }

      case 'investigators': {
        return (
          <NetworkPIList
            appliedFilters={appliedFilters}
            centerCount={centerCount}
            networkType={networkType}
            piList={piList}
            innoplexusId={networkIdToModify}
          />
        );
      }

      case 'centers': {
        return (
          <NetworkCenterList
            appliedFilters={appliedFilters}
            piCount={piCount}
            networkType={networkType}
            innoplexusId={networkIdToModify}
            centerList={piList}
          />
        );
      }

      default: {
        return <div className="no-data-found-msg">No data available</div>;
      }
    }
  };

  const onSwitchChange = (checked) => {
    setSwitchChange(checked);
  };

  const applyFilters = (filtersObj) => {
    setAppliedFilters({ ...appliedFilters, ...filtersObj });
  };

  const onIndicationChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'indications',
        }),
      );
    } else {
      dispatch(
        getautosuggestvalueAction({
          query: '',
          type: 'indications',
        }),
      );
    }
  };

  const handleTherapyChange = (Therapy) => {
    setTherapy(Therapy);
  };

  // const handleAddAddress = (value, typeofinput) => {
  //   switch (typeofinput) {
  //     case 'line1':
  //       setAddressOfNewtork({ ...addressOfNetwork, line1: value });
  //       break;
  //     case 'line2':
  //       setAddressOfNewtork({ ...addressOfNetwork, line2: value });
  //       break;
  //     case 'city':
  //       setAddressOfNewtork({ ...addressOfNetwork, city: value });
  //       break;
  //     case 'state':
  //       setAddressOfNewtork({ ...addressOfNetwork, state: value });
  //       break;
  //     case 'zip':
  //       setAddressOfNewtork({ ...addressOfNetwork, zip: value });
  //       break;
  //     default:
  //   }
  // };

  const handleEditNtwrkClick = () => {
    setEditModal(true);
  };

  const submitNewNetwork = () => {
    const regex = /^[_]*[0-9]{8}$/;
    if (regex.test(networkName)) {
      setNetworkNameError(true)
      setEditModal(true)
    } else {
      setNetworkNameError(false)
      dispatch(
        modifyNetworkAction({
          innoplexus_id: networkIdToModify,
          is_deleting: false,
          body: {
            name: networkName.trim(),
            indications,
            therapy_area: therapy,
            is_network_validated: isValidated,
            network_validated_on: isValidated === false ? 0 : selectedDate,
            description,
            network_owner_ids: selectedUser,
            // address: {
            //   address_line_1: addressOfNetwork.line1,
            //   address_line_2: addressOfNetwork.line2,
            //   city: addressOfNetwork.city,
            //   state: addressOfNetwork.state,
            //   zip: addressOfNetwork.zip,
            // },
            // legal_entity: legalEntity,
            // payment_structure: paymentStructure,
            // percent_overhead_charged: percentCharge,
            network_affiliation: networkType,
            // survey_email: surveyEmail,
            // network_type: {
            //   embedded_site_model: centeralizedServices.embeddedSiteModal,
            //   central_feasibility: centeralizedServices.centeralFeasibility,
            //   central_budget: centeralizedServices.centralBudget,
            //   centrally_coordinated_patient_recruitment_services: centeralizedServices.centrallyCoordinates,

            //   site_contracts: centeralizedServices.siteContracts,

            //   site_payment_support: centeralizedServices.sitePaymentSupport,

            //   project_management: centeralizedServices.projectManagement,
            //   provision_of_equipment: centeralizedServices.provisionOfEquipment,
            //   provision_of_equipment_description: provisionOfEquipmentDesc,
            // },
            // feasibility_contact_details: {
            //   name: surveyPersonDetails.surveyPersonName,
            //   email: surveyPersonDetails.surveyPersonEmail,
            //   phone_number: surveyPersonDetails.surveyPersonContact,
            // },
            // feasibility_process: feasibilityProcess,
          },
        },
        {
          status_flag: 'Updated',
        }),
      );
    }
  };

  const deleteNetwork = () => {
    setDeleteNetworkModal(true)
  }

  const handleDeleteNetwork = () => {
    dispatch(
      deleteNetworkAction({
        innoplexus_id: networkIdToModify,
        is_deleting: true,
        body: {
          name: networkName.trim(),
          indications,
          therapy_area: therapy,
          description,
          // address: {
          //   address_line_1: addressOfNetwork.line1,
          //   address_line_2: addressOfNetwork.line2,
          //   city: addressOfNetwork.city,
          //   state: addressOfNetwork.state,
          //   zip: addressOfNetwork.zip,
          // },
          // legal_entity: legalEntity,
          // payment_structure: paymentStructure,
          // percent_overhead_charged: percentCharge,
          network_affiliation: networkType,
          // survey_email: surveyEmail,
          // network_type: {
          //   embedded_site_model: centeralizedServices.embeddedSiteModal,
          //   central_feasibility: centeralizedServices.centeralFeasibility,
          //   central_budget: centeralizedServices.centralBudget,
          //   centrally_coordinated_patient_recruitment_services: centeralizedServices.centrallyCoordinates,

          //   site_contracts: centeralizedServices.siteContracts,

          //   site_payment_support: centeralizedServices.sitePaymentSupport,

          //   project_management: centeralizedServices.projectManagement,
          //   provision_of_equipment: centeralizedServices.provisionOfEquipment,
          //   provision_of_equipment_description: provisionOfEquipmentDesc,
          // },
          // feasibility_contact_details: {
          //   name: surveyPersonDetails.surveyPersonName,
          //   email: surveyPersonDetails.surveyPersonEmail,
          //   phone_number: surveyPersonDetails.surveyPersonContact,
          // },
          // feasibility_process: feasibilityProcess,
        },
      }),
    );
    setDeleteNetworkModal(false)
  }

  const handleOnChange = (k) => {
    setSelectedUser(k);
  }

  const changeCheckBox = (e) => {
    if (e.target.checked) {
      setIsValidated(true)
    } else {
      setSelectedDate(undefined)
      setIsValidated(false)
      setDate(undefined)
    }
  }

  const handleDateChange = (e) => {
    if (e !== null) {
      const date1 = moment(e._d).unix();
      setSelectedDate(parseInt(date1 / 86400, 10) * 86400)
      setDate(parseInt(date1 / 86400, 10) * 86400)
    } else {
      setSelectedDate(undefined)
      setDate(undefined)
    }
  }

  const handleApiCall = () => {
    dispatch(
      modifyNetworkAction({
        innoplexus_id: networkIdToModify,
        is_deleting: false,
        body: {
          is_network_validated: isValidated,
          network_owner_ids: selectedUser,
          network_validated_on: isValidated === false ? 0 : selectedDate,
          name: networkName.trim(),
        },
      },
      {
        status_flag: 'Validated',
      }),
    );
    setIsValidatedModal(false)
  }

  const handleNetworkType = (value) => {
    // setNetworkType(value)
    if (value === 'Investigator Network' && centerCount !== 0) {
      notify('You have to remove all centers first and then you can change network type', 'error')
    } else
    if (value === 'Center Network' && piCount !== 0) {
      notify('You have to remove all investigators first and then you can change network type', 'error')
    } else {
      setNetworkType(value)
    }
  }

  const disabledBtn = () => {
    return networkName === '' || selectedUser.length === 0 || networkType === '' || networkNameAlreadyUsed
  }

  return (
    <>
      {editModal && (
      <Modal
        title="Edit network Details"
        className="create-network-modal-main"
        visible={editModal}
        onCancel={() => {
          const network_id = parse(location.search).id;
          const from = 1;
          const size = 10;
          const filters = JSON.stringify({ parent_flag: false });

          dispatch(
            getNetworkDetailsAction({
              type: activeTab === 'investigators' || activeTab === 'networkDetails1' ? 'investigators' : 'centers',
              network_id,
              from,
              size,
              filters,
            }),
          );
          setEditModal(false)
        }}
        okText="Save"
        okButtonProps={{ disabled: disabledBtn() }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={submitNewNetwork}
      >
        <div className="create-network-content">
          <div className="create-network-input">
            <label>Therapy Area</label>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Select Therapy area"
              onChange={handleTherapyChange}
              value={therapy}
            >
              {therapyArea.map((area) => (
                <Option key={area}>{area}</Option>
              ))}
            </Select>
          </div>

          <div className="create-network-input">
            <label>Indication</label>
            <MultiAutoSelect
              apiCall={onIndicationChangeInput}
              apiName="autosuggestdata"
              fvalue={indications}
              setValue={(val) => {
                setIndication(val);
              }}
              cancelAction={getautosuggestionCancelAction}
              resetAction={getautosuggestionResetAction}
              key="indication"
            />
          </div>
          <div className="create-network-input">
            <label>Network Type
              <span className="asterisk-red">*</span>
            </label>
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Select Network Type"
              onChange={(value) => handleNetworkType(value)}
              value={networkType}
            >
              {NetworkTypeFilter.map((str) => (
                <Option key={str}>{str}</Option>
              ))}
            </Select>
          </div>

          <div className="create-network-input">
            <label>Owner name
              <span className="asterisk-red">*</span>
            </label>
            <Select
              disabled={usersList.loading}
              value={selectedUser}
              mode="multiple"
              className="share-search-input"
              placeholder="Search name here"
              onChange={handleOnChange}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
              {usersList?.data?.data?.users?.map((itm) => (
                <Option
                  value={itm.id}
                >
                  {itm.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className="create-network-input">
            <label>
              Network Name
              <span className="asterisk-red">*</span>
            </label>
            <Input
              placeholder="Enter Network name"
              value={networkName}
              onChange={(e) => setNetworkName(e.target.value)}
            />
            {networkNameAlreadyUsed ? (
              <div className="error-msg">
                This Network name already used. Please try with different network name!{' '}
              </div>
            ) : null}
            {
            networkNameError ? (
              <div className="error-msg">
                Enter proper network name !{' '}
              </div>
            ) : null
          }
          </div>
          <div className="create-network-input" style={{ minWidth: '96%' }}>
            <label>Description</label>
            <Input
              placeholder="Enter Description for Network"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {/* <div
            className="create-network-input create-network-input-section create-network-input-section-top"
            style={{ minWidth: '47%', marginRight: 0, paddingRight: '20px' }}
          >
            <label>Address line 1</label>
            <Input
              placeholder="Enter address line 1"
              value={addressOfNetwork.line1}
              onChange={(e) => handleAddAddress(e.target.value, 'line1')}
            />
          </div>
          <div
            className="create-network-input create-network-input-section create-network-input-section-top"
            style={{ minWidth: '49%', marginLeft: 0, paddingLeft: '20px' }}
          >
            <label>Address Line 2</label>
            <Input
              placeholder="Enter address line 2"
              value={addressOfNetwork.line2}
              onChange={(e) => handleAddAddress(e.target.value, 'line2')}
            />
          </div>
          <div className="create-network-input">
            <label>City</label>
            <Input
              placeholder="Enter city"
              value={addressOfNetwork.city}
              onChange={(e) => handleAddAddress(e.target.value, 'city')}
            />
          </div>
          <div className="create-network-input">
            <label>State</label>
            <Input
              placeholder="Enter state"
              value={addressOfNetwork.state}
              onChange={(e) => handleAddAddress(e.target.value, 'state')}
            />
          </div>
          <div className="create-network-input">
            <label>Zip code</label>
            <Input
              placeholder="Enter zip code"
              value={addressOfNetwork.zip}
              onChange={(e) => handleAddAddress(e.target.value, 'zip')}
            />
          </div>
          <div className="create-network-input">
            <label>Legal entity</label>
            <Input
              placeholder="Enter legal entity"
              value={legalEntity}
              onChange={(e) => setLegalEntity(e.target.value)}
            />
          </div>
          <div className="create-network-input">
            <label>Payment structure</label>
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Select payment structure"
              onChange={(value) => setPaymentStructure(value)}
              value={paymentStructure}
            >
              {paymentStructureFilter.map((str) => (
                <Option key={str}>{str}</Option>
              ))}
            </Select>
          </div>
          <div className="create-network-input">
            <label>Percent overhead charged</label>
            <InputNumber
              placeholder="Enter percent overhead charged"
              value={percentCharge}
              onChange={(e) => setPercentCharge(e)}
            />
          </div>
          <div className="create-network-input">
            <label> Survey Email Address</label>
            <Input
              placeholder="Enter survey address"
              value={surveyEmail}
              onChange={(e) => setSurveyEmail(e.target.value)}
            />
          </div>
          <div className="create-network-input create-network-input-section">
            <label className="main-title">Centralized services offered</label>
            <div className="create-network-radio-group">
              <div className="create-network-radio-input">
                <label>Embedded site model</label>
                <Radio.Group
                  onChange={(e) => setCenteralizedServices({
                    ...centeralizedServices,
                    embeddedSiteModal: e.target.value,
                  })}
                  value={centeralizedServices.embeddedSiteModal}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Central feasibility</label>
                <Radio.Group
                  onChange={(e) => setCenteralizedServices({
                    ...centeralizedServices,
                    centeralFeasibility: e.target.value,
                  })}
                  value={centeralizedServices.centeralFeasibility}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Central budget, central contract</label>
                <Radio.Group
                  onChange={(e) => setCenteralizedServices({
                    ...centeralizedServices,
                    centralBudget: e.target.value,
                  })}
                  value={centeralizedServices.centralBudget}
                >
                  <Radio value="Yes MANDATORY">
                    Yes, Mandatory (Potential budget to be confirmed)
                  </Radio>
                  <Radio value="Yes OPTIONAL">
                    {' '}
                    Yes, Optional (Potential budget to be confirmed)
                  </Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Site contracts, central assistance only</label>
                <Radio.Group
                  onChange={(e) => setCenteralizedServices({
                    ...centeralizedServices,
                    siteContracts: e.target.value,
                  })}
                  value={centeralizedServices.siteContracts}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Site payment support</label>
                <Radio.Group
                  onChange={(e) => setCenteralizedServices({
                    ...centeralizedServices,
                    sitePaymentSupport: e.target.value,
                  })}
                  value={centeralizedServices.sitePaymentSupport}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Centrally coordinated patient recruitment services</label>
                <Radio.Group
                  onChange={(e) => setCenteralizedServices({
                    ...centeralizedServices,
                    centrallyCoordinates: e.target.value,
                  })}
                  value={centeralizedServices.centrallyCoordinates}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Project management</label>
                <Radio.Group
                  onChange={(e) => setCenteralizedServices({
                    ...centeralizedServices,
                    projectManagement: e.target.value,
                  })}
                  value={centeralizedServices.projectManagement}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input extra-field-input">
                <label>Provision of equipment</label>
                <Radio.Group
                  onChange={(e) => setCenteralizedServices({
                    ...centeralizedServices,
                    provisionOfEquipment: e.target.value,
                  })}
                  value={centeralizedServices.provisionOfEquipment}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                {centeralizedServices.provisionOfEquipment === 'Yes' ? (
                  <div className="extra-field-input-sec">
                    <label>Provision of equipment description</label>
                    <Input
                      placeholder="Enter provision of equipment description"
                      value={provisionOfEquipmentDesc}
                      onChange={(e) => setProvisionOfEquipmentDesc(e.target.value)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="create-network-input create-network-input-section">
            <label>Feasibility contact person</label>
            <div className="create-network-combine-input">
              <div className="create-network-input">
                <label>Name</label>
                <Input
                  placeholder="Enter name of feasibility contact person"
                  value={surveyPersonDetails.surveyPersonName}
                  onChange={(e) => setSurveyPersonDetails({
                    ...surveyPersonDetails,
                    surveyPersonName: e.target.value,
                  })}
                />
              </div>
              <div className="create-network-input">
                <label>Contact</label>
                <Input
                  placeholder="Enter contact no. of feasibility contact person"
                  value={surveyPersonDetails.surveyPersonContact}
                  onChange={(e) => setSurveyPersonDetails({
                    ...surveyPersonDetails,
                    surveyPersonContact: e.target.value,
                  })}
                />
              </div>
              <div className="create-network-input">
                <label>Email</label>
                <Input
                  placeholder="Enter email of feasibility contact person"
                  value={surveyPersonDetails.surveyPersonEmail}
                  onChange={(e) => setSurveyPersonDetails({
                    ...surveyPersonDetails,
                    surveyPersonEmail: e.target.value,
                  })}
                />
              </div>
            </div>
          </div>
          <div className="create-network-input" style={{ minWidth: '97%' }}>
            <label>Feasibility process</label>
            <Select
              style={{
                minWidth: '100%',
              }}
              placeholder="Select feasibility process"
              onChange={(value) => setFeasibilityProcess(value)}
              value={feasibilityProcess}
            >
              {FeasibilityProcessFilter.map((str) => (
                <Option key={str}>{str}</Option>
              ))}
            </Select>
          </div> */}
        </div>
      </Modal>
      )}
      {deleteNetworkModal && (
      <Modal
        title="Delete Network"
        visible={deleteNetworkModal}
        onOk={handleDeleteNetwork}
        onCancel={() => setDeleteNetworkModal(false)}
      >
        Do you really want to delete this Network ?
      </Modal>
      )}
      <Loader loading={modifyNetworkRespose.loading}>

        <div className="whole-search-page">
          <div className="middle-part network-page-content">

            {!networkData.loading && (
            <>
              <div className="header-network-content">
                <Tooltip title={networkMetaDetails.name}>
                  <div className="header-network-content-title">
                    {networkMetaDetails.name}
                  </div>
                </Tooltip>
                <div className="validation-btn">
                  <Tooltip title={networkData?.data?.data?.network_details?.is_network_validated ? `Network validated on : ${getFinalDate()}` : 'Network Validate'}>
                    <Button
                      className={`${networkData?.data?.data?.network_details?.is_network_validated ? 'network-validated' : 'network-not-validated'}`}
                      onClick={() => setIsValidatedModal(true)}
                      disabled={!getCookie('permissions').includes('super_admin') && !selectedUser.includes(userId)}
                    />
                  </Tooltip>
                </div>
                <div className="edit-delete-button">
                  <Tooltip title="Edit network">

                    <Button
                      className="edit-icon-btn"
                      type="primary"
                      onClick={handleEditNtwrkClick}
                      disabled={!getCookie('permissions').includes('super_admin') && !selectedUser.includes(userId)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete network">
                    <Button
                      className="del-icon-btn"
                      type="primary"
                      onClick={deleteNetwork}
                      disabled={!getCookie('permissions').includes('super_admin') && !selectedUser.includes(userId)}
                    />
                  </Tooltip>
                </div>
              </div>
            </>
            )}

            <div className="tabs-content card tab-content-card">
              <div className="upper-divof-search-Tabs">
                <div className="sub-header-search">
                  <div className="searbar-tabs">
                    <TagsBar
                      activeTab={activeTab}
                      tags={navTabs}
                      onTabChange={onTabChange}
                    />
                  </div>
                </div>
              </div>
              {
          activeTab !== 'networkDetails1' && (
            <Tooltip title="Filters">
              <Switch
                onChange={onSwitchChange}
                className="switch-sidebar-content"
                style={{ top: '65px', left: '30px' }}
              />
            </Tooltip>

          )
        }

              <div className="searchbar-data">
                <div className="search-result" style={{ maxHeight: 'calc(100vh - 187px)' }}>

                  {switchChange ? (
                    <div className="filter-wrap">

                      <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        className="site-collapse-custom-collapse"
                      >
                        <Panel
                          header="Filters"
                          key="1"
                          className="site-collapse-custom-panel sidebar-menu center-map-sidebar sidebar-filter-i-c"
                        >
                          {activeTab !== 'networkDetails1' ? (
                            <div className="filter-content">

                              <SearchResultFilters
                                dataset={activeTab}
                                appliedFilters={appliedFilters}
                                onApplyFilters={applyFilters}
                                onReset={resetFilters}
                              />

                            </div>
                          ) : null}

                        </Panel>
                      </Collapse>
                    </div>
                  ) : null}
                  {renderActiveTabContent()}

                </div>
              </div>
            </div>
          </div>

        </div>
      </Loader>

      {isValidatedModal && (
        <Modal
          title="Validate Network Modal"
          open={isValidatedModal}
          onCancel={() => {
            const network_id = parse(location.search).id;
            const from = 1;
            const size = 10;
            const filters = JSON.stringify({ parent_flag: false });

            dispatch(
              getNetworkDetailsAction({
                type: activeTab === 'investigators' || activeTab === 'networkDetails1' ? 'investigators' : 'centers',
                network_id,
                from,
                size,
                filters,
              }),
            );
            setIsValidatedModal(false)
          }}
          onOk={() => handleApiCall()}
          className="network-validated-modal"
          okButtonProps={{ disabled: (isValidated ? ((date === 0 || date === '' || date === undefined)) : false) }}
        >
          <div className="validation-modal">
            <div className="validation-modal-content">
              <div className="validation-modal-content-checkbox">
                <Checkbox onChange={(e) => changeCheckBox(e)} checked={isValidated} />
              </div>
              <div className="validation-modal-content-text">
                Is Validated?
              </div>
            </div>
            {isValidated ? (
              <div className="validation-modal-content-date">
                <div className="validation-modal-content-title"> Date when validated : </div>
                <div className="validation-modal-content-date">
                  <DatePicker
                    format="DD-MMM-YYYY"
                    value={getValue()}
                    onChange={(e) => handleDateChange(e)}
                    disabledDate={(current) => current && current.valueOf() > Date.now()}
                  />
                </div>
              </div>
            )

              : ''}
          </div>
        </Modal>

      )}

    </>

  );
}

export default NetworkDetails;
