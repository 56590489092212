import {
  map, catchError, mergeMap, takeUntil,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { stringify } from 'query-string';
import { GENERIC_ACTION } from '../SearchResults/logic'
import { apiCall } from '../../../utils';

const GET_NETWORK_DETAILS = 'GET_NETWORK_DETAILS'
const GET_NETWORK_DETAILS_SUCCESS = 'GET_NETWORK_DETAILS_SUCCESS'
const GET_NETWORK_DETAILS_RESET = 'GET_NETWORK_DETAILS_RESET'
const GET_NETWORK_DETAILS_MORE = 'GET_NETWORK_DETAILS_MORE'
const GET_NETWORK_DETAILS_MORE_SUCCESS = 'GET_NETWORK_DETAILS_MORE_SUCCESS'
const GET_NETWORK_DETAILS_FAILURE = 'GET_NETWORK_DETAILS_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getNetworkDetailsAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_NETWORK_DETAILS,
      url: `${process.env.apiUrl}v0/search/network/deepdive`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getNetworkDetailsMoreAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_NETWORK_DETAILS_MORE,
      url: `${process.env.apiUrl}v0/search/network/deepdive`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI removed from Network successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getNetworkDeatilsResetAction = () => ({
  type: GET_NETWORK_DETAILS_RESET,
})

export function getNetworkDetailsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_NETWORK_DETAILS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_NETWORK_DETAILS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_NETWORK_DETAILS_MORE_SUCCESS: {
      return {
        data: {
          data: {
            network_details: action.payload.data.network_details,
            pi_summary: [...state.data.data.pi_summary,
              ...action.payload.data.pi_summary],
            totalPIs: action.payload.data.totalPIs,

          },
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_NETWORK_DETAILS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_NETWORK_DETAILS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const DELETE_PI_FROM_NETWORK = 'DELETE_PI_FROM_NETWORK'
const DELETE_PI_FROM_NETWORK_SUCCESS = 'DELETE_PI_FROM_NETWORK_SUCCESS'
const DELETE_PI_FROM_NETWORK_RESET = 'DELETE_PI_FROM_NETWORK_RESET'
const DELETE_PI_FROM_NETWORK_FAILURE = 'DELETE_PI_FROM_NETWORK_FAILURE'

export const deletePiFromNetworkAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: DELETE_PI_FROM_NETWORK,
      url: `${process.env.apiUrl}/v0/search/modifyPItoNetworks?pi_id=${payload.pi_id}&type=${payload.type}`,
      method: 'DELETE',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Network successfully updated',
    // hideNotification: false, // hide notification on error
  })
}

export const deletePiFromNetworkResetAction = () => ({
  type: DELETE_PI_FROM_NETWORK_RESET,
})

export function deletePiFromNetworkReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case DELETE_PI_FROM_NETWORK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_PI_FROM_NETWORK_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_PI_FROM_NETWORK_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_PI_FROM_NETWORK_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_PI_AUTO_SUGGEST = 'GET_PI_AUTO_SUGGEST'
const GET_PI_AUTO_SUGGEST_SUCCESS = 'GET_PI_AUTO_SUGGEST_SUCCESS'
const GET_PI_AUTO_SUGGEST_MORE = 'GET_PI_AUTO_SUGGEST_MORE'
const GET_PI_AUTO_SUGGEST_MORE_SUCCESS = 'GET_PI_AUTO_SUGGEST_MORE_SUCCESS'
const GET_PI_AUTO_SUGGEST_CANCEL = 'GET_PI_AUTO_SUGGEST_CANCEL'
const GET_PI_AUTO_SUGGEST_RESET = 'GET_PI_AUTO_SUGGEST_RESET'
const GET_PI_AUTO_SUGGEST_FAILURE = 'GET_PI_AUTO_SUGGEST_FAILURE'

export const getPiAutoSuggestAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PI_AUTO_SUGGEST,
      url: `${process.env.apiUrl}v0/search/list`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI removed from Network successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getPiAutoSuggestMoreAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PI_AUTO_SUGGEST_MORE,
      url: `${process.env.apiUrl}v0/search/list`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI removed from Network successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getPiAutoSuggestResetAction = () => ({
  type: GET_PI_AUTO_SUGGEST_RESET,
})

export const getPiAutoSuggestCancelAction = () => ({
  type: GET_PI_AUTO_SUGGEST_CANCEL,
})

export function getPiAutoSuggestReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PI_AUTO_SUGGEST: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PI_AUTO_SUGGEST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PI_AUTO_SUGGEST_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PI_AUTO_SUGGEST_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_PI_AUTO_SUGGEST_FAILURE:
    case GET_PI_AUTO_SUGGEST_CANCEL: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const PUT_PI_TO_NETWORK = 'PUT_PI_TO_NETWORK'
const PUT_PI_TO_NETWORK_SUCCESS = 'PUT_PI_TO_NETWORK_SUCCESS'
const PUT_PI_TO_NETWORK_CANCEL = 'PUT_PI_TO_NETWORK_CANCEL'
const PUT_PI_TO_NETWORK_RESET = 'PUT_PI_TO_NETWORK_RESET'
const PUT_PI_TO_NETWORK_FAILURE = 'PUT_PI_TO_NETWORK_FAILURE'

export const putPiToNetworkAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: PUT_PI_TO_NETWORK,
      url: `${process.env.apiUrl}v0/search/addMultiplePIstoNetwork?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: meta.status_flag === 'Project' ? 'PIs added in the network' : 'Network successfully updated',
    // hideNotification: false, // hide notification on error
  })
}

export const putPiToNetworkResetAction = () => ({
  type: PUT_PI_TO_NETWORK_RESET,
})

export const putPiToNetworkCancelAction = () => ({
  type: PUT_PI_TO_NETWORK_CANCEL,
})

export function putPiToNetworkReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case PUT_PI_TO_NETWORK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case PUT_PI_TO_NETWORK_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case PUT_PI_TO_NETWORK_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case PUT_PI_TO_NETWORK_FAILURE:
    case GET_PI_AUTO_SUGGEST_CANCEL:
    {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }

    default:
      return state
  }
}

const MODIFY_NETWORK = 'MODIFY_NETWORK';
const MODIFY_NETWORK_CANCEL = 'MODIFY_NETWORK_CANCEL';
const MODIFY_NETWORK_RESET = 'MODIFY_NETWORK_RESET';
// const MODIFY_NETWORK_CONFLICT = 'MODIFY_NETWORK_CONFLICT';
const MODIFY_NETWORK_SUCCESS = 'MODIFY_NETWORK_SUCCESS';
const MODIFY_NETWORK_FAILURE = 'MODIFY_NETWORK_FAILURE';

export const modifyNetworkCancelAction = () => ({
  type: MODIFY_NETWORK_CANCEL,
});

// Auto Complete action
// export const modifyNetworkAction = (payload) => ({
//   type: MODIFY_NETWORK,
//   payload,
// });

// // Auto Complete Success action
// const modifyNetworkSuccessAction = (payload) => console.log('jjjjj', payload)(
//   {
//     type: MODIFY_NETWORK_SUCCESS,
//     payload,
//     showNotification: true,
//     message: payload.body.is_network_validated ? 'Network validated successfully' : 'Network updated successfully',
//   },
// );

// // Add project reset action
// export const modifyNetworkResetAction = () => ({
//   type: MODIFY_NETWORK_RESET,
// });

// export const modifyNetworkEpic = (actions$) => actions$.pipe(
//   ofType(MODIFY_NETWORK),
//   mergeMap((action) => ajax(
//     apiCall(`${process.env.apiUrl}v0/search/updateNetwork?innoplexus_id=${action.payload.innoplexus_id}&is_deleting=${action.payload.is_deleting}`, 'PUT', action.payload.body, true),
//   ).pipe(
//     map((res) => modifyNetworkSuccessAction(res)),
//     takeUntil(actions$.pipe(ofType(MODIFY_NETWORK_CANCEL))),
//     catchError((err) => of({
//       type: MODIFY_NETWORK_CONFLICT,
//       payload: err,
//     })),
//   )),
// );

// export function modifyNetworkReducer(state, action) {
//   if (typeof state === 'undefined') {
//     return INITIAL_STATE
//   }
//   switch (action.type) {
//     case MODIFY_NETWORK: {
//       return {
//         data: {},
//         loading: true,
//         flag: false,
//         error: false,
//       };
//     }
//     case MODIFY_NETWORK_SUCCESS: {
//       return {
//         data: action.payload.response.data,
//         loading: false,
//         flag: true,
//         error: false,
//       };
//     }
//     case MODIFY_NETWORK_RESET: {
//       return {
//         data: {},
//         // data: action.payload.response.data,
//         loading: false,
//         flag: false,
//         error: false,
//       };
//     }
//     case MODIFY_NETWORK_FAILURE: {
//       return {
//         data: action.payload.response.data,
//         loading: false,
//         flag: false,
//         error: true,
//       };
//     }
//     case MODIFY_NETWORK_CONFLICT: {
//       return {
//         data: action.payload.response,
//         loading: false,
//         flag: false,
//         error: true,
//       };
//     }
//     default:
//       return state;
//   }
// }

export const modifyNetworkAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: MODIFY_NETWORK,
      url: `${process.env.apiUrl}v0/search/updateNetwork?innoplexus_id=${payload.innoplexus_id}&is_deleting=${payload.is_deleting}`,
      method: 'PUT',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: meta.status_flag === 'Validated' ? 'Network validated successfully' : 'Network updated successfully',
    hideNotification: true,
  })
}

export const modifyNetworkResetAction = () => ({
  type: MODIFY_NETWORK_RESET,
})

export function modifyNetworkReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case MODIFY_NETWORK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case MODIFY_NETWORK_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case MODIFY_NETWORK_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case MODIFY_NETWORK_FAILURE: {
      return {
        data: action.payload.response,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// delete the network

const DELETE_NETWORK = 'DELETE_NETWORK';
const DELETE_NETWORK_CANCEL = 'DELETE_NETWORK_CANCEL';
const DELETE_NETWORK_RESET = 'DELETE_NETWORK_RESET';
const DELETE_NETWORK_SUCCESS = 'DELETE_NETWORK_SUCCESS';
const DELETE_NETWORK_FAILURE = 'DELETE_NETWORK_FAILURE';

export const deleteNetworkCancelAction = () => ({
  type: DELETE_NETWORK_CANCEL,
});

// Auto Complete action
export const deleteNetworkAction = (payload) => ({
  type: DELETE_NETWORK,
  payload,
});

// Auto Complete Success action
const deleteNetworkSuccessAction = (payload) => ({
  type: DELETE_NETWORK_SUCCESS,
  payload,
  showNotification: true,
  message: 'Network deleted successfully',
});

// Add project reset action
export const deleteNetworkResetAction = () => ({
  type: DELETE_NETWORK_RESET,
});

export const deleteNetworkEpic = (actions$) => actions$.pipe(
  ofType(DELETE_NETWORK),
  mergeMap((action) => ajax(
    apiCall(`${process.env.apiUrl}v0/search/updateNetwork?innoplexus_id=${action.payload.innoplexus_id}&is_deleting=${action.payload.is_deleting}`, 'PUT', action.payload.body, true),
  ).pipe(
    map((res) => deleteNetworkSuccessAction(res)),
    takeUntil(actions$.pipe(ofType(DELETE_NETWORK_CANCEL))),
    catchError((err) => of({
      type: DELETE_NETWORK_RESET,
      payload: err,
    })),
  )),
);

export function deleteNetworkReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case DELETE_NETWORK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case DELETE_NETWORK_SUCCESS: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case DELETE_NETWORK_RESET: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: false,
        error: false,
      };
    }
    case DELETE_NETWORK_FAILURE:
    case DELETE_NETWORK_CANCEL: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: false,
        error: true,
      };
    }

    default:
      return state;
  }
}
