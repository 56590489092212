import React, { useState } from 'react'
// import AllianceNonAllianceMap from './AllianceNonAllianceMap'
import { Tooltip } from 'antd'
import { parse } from 'query-string'
import ChoroplethMapDrillDown from './ShortlistedVsCandidatesMap'
import CompetitiveMap from './CompetitiveMap'
import { isSponsor } from '../../../utils'
import SearchBar from '../SearchBar'

const MapViewSavedProjectResults = ({
  data, project_id, mapFullScreen, setMapFullScreen, projectFilters, cflStatusSelected, setCflStatusSelected, handleStatusChange, handleApplyFieldFilterClicked, handleClearFieldFilterClicked, statusList, lcorStatusSelected, setLcorStatusSelected, lcorStatusList,
}) => {
  const [currentTab, setCurrentTab] = useState('shortlisted_vs_candidate')
  const [displayAddBtnsOverlay, setDisplayAddBtnsOverlay] = useState(false);
  const [searchPi, setSearchPi] = useState(false);
  const [valueToCheck, setValueToCheck] = useState(false);
  const tabs = [
    {
      title: 'Shortlisted Vs Candidates',
      key: 'shortlisted_vs_candidate',
    },
    {
      title: 'Competitive Environment',
      key: 'competitive_environment',
      // additionalClass: 'disabled',
    },
  ]

  const renderTab = () => {
    switch (currentTab) {
      case 'shortlisted_vs_candidate': return (
        <ChoroplethMapDrillDown
          data={data}
          project_id={project_id}
          mapFullScreen={mapFullScreen}
          setMapFullScreen={setMapFullScreen}
          projectFilters={projectFilters}
          cflStatusSelected={cflStatusSelected}
          setCflStatusSelected={setCflStatusSelected}
          handleStatusChange={handleStatusChange}
          handleApplyFieldFilterClicked={handleApplyFieldFilterClicked}
          handleClearFieldFilterClicked={handleClearFieldFilterClicked}
          statusList={statusList}
          lcorStatusSelected={lcorStatusSelected}
          setLcorStatusSelected={setLcorStatusSelected}
          lcorStatusList={lcorStatusList}
        />
      )
      case 'competitive_environment': return <CompetitiveMap data={data} flag="competitive" project_id={project_id} mapFullScreen={mapFullScreen} setMapFullScreen={setMapFullScreen} projectFilters={projectFilters} />
      default: return <div>Under development </div>
    }
  }

  const OnAddInvestigatorClick = () => {
    setSearchPi(true);
  };

  return (
    <div className="map-view-main-div">
      {!mapFullScreen
      && (
        <div className="details-page-tabs country-project-tabs-list-tab">
          <div className="searbar-tabs">
            {tabs.map((tabItm, index) => (
              <div
                className={`tagsbutton ${currentTab === tabItm.key ? 'active' : ''} ${tabItm.additionalClass}`}
                role="presentation"
                onClick={() => {
                  if (currentTab !== tabItm.key) {
                    setCurrentTab(tabItm.key);
                  }
                }}
                title={tabItm.key}
                key={index}
              >
                <div className="tab-title">
                  {tabItm.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="map-view-map-section">
        {renderTab()}
      </div>
      {displayAddBtnsOverlay ? (
        <div className="footer-button-wrapper footer-button-wrapper-pd save-analysis add-overlay-to-map">
          <div className="plus-add-btn plus-add-btn-content map-view">
            <button
              onClick={() => {
                OnAddInvestigatorClick();
              }}
              type="button"
              className="plus-btn"
            >
              <span className="title">Add Investigator&nbsp;</span>
            </button>
          </div>
          <div className="plus-add-btn close-add-btn">
            <button
              onClick={() => setDisplayAddBtnsOverlay(false)}
              type="button"
              className="plus-btn"
            >
              <span className="title-plus cross-btn">X</span>
            </button>
          </div>
        </div>
      ) : currentTab === 'shortlisted_vs_candidate'
        ? (
          <div className="footer-button-wrapper footer-button-wrapper-pd save-analysis add-overlay-to-me add-no-overlay-to-me">
            <div className="plus-add-btn">
              <button onClick={() => setDisplayAddBtnsOverlay(true)} disabled={isSponsor()} type="button" className="plus-btn">
                <Tooltip title="Add investigator">
                  <span className="title-plus">+</span>
                </Tooltip>
              </button>
            </div>
          </div>
        ) : ' '}

      {searchPi && (
      <div className="investigator-wrap">
        <SearchBar
          handleModal={{
            flagName: 'savedAnalysis',
            analysisId: parse(location.search).project_id,
          }}
          searchPi={searchPi}
          setsearchPi={setSearchPi}
          fieldToSearch="investigators"
          setValueToCheck={setValueToCheck}
          valueToCheck={valueToCheck}
        />
      </div>
      )}
    </div>
  )
}

export default MapViewSavedProjectResults;
