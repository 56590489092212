import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, Select, Input, Radio, Checkbox,
  Spin,
} from 'antd';
import Loader from '../../../common/components/Loader';
import OutsideClick from '../../../common/components/OutsideClick';
import {
  getAutocompleteValuesAction,
  getAutocompleteValuesResetAction,
  getAutocompleteValuesCancelAction,
} from '../SearchBar/logic';
import {
  uploadSponsorsFileAction,
  addColumnMappingAction,
  getUploadedPIsMetaAction,
  getUploadedPIsListAction,
  updateUploadedPIAction,
  addUploadedPIsAction,
  uploadSponsorsFileResetAction,
  updateUploadedPIResetAction,
} from './logic';
import { getTemplate } from '../SearchBar/template';
import AddInvestigator from '../../components/AddInvestigator';
import { addPiResetAction } from '../../components/AddInvestigator/logic';
import { getDatasetListDataAction, getDatasetListResetAction } from '../SearchResults/logic';
import DocumentList from '../DocumentList';
import { notify } from '../../../utils';

const { Option } = Select;
const { Search } = Input;

const sponsorsPiActionOpts = [
  {
    key: 'Add matched PI to project',
    value: 'insert_1',
  },
  {
    key: 'Search PI in LandSCAPE',
    value: 'insert_2',
  },
  {
    key: 'Create a new PI request in landSCAPE and add to project',
    value: 'insert',
  },
  // {
  //   key: 'Add selected PI in LandSCAPE',
  //   value: 'insert_3',
  // },
  {
    key: 'No action',
    value: 'no_action',
  },
]

const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

const requiredMapFields = ['first_name', 'last_name', 'po_name', 'po_city', 'po_country'];
const UploadSponsorsList = (props) => {
  const dispatch = useDispatch();
  const countsData = useSelector((state) => state.countsData);
  const datasetListData = useSelector((state) => state.datasetListData);
  const [currentStep, setCurrentStep] = useState('fileUpload');
  const [displayModal, setDisplayModal] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [isAddPiModalOpen, setIsAddPiModalOpen] = useState(false)
  const [piObjforAdd, setPiObjforAdd] = useState({})
  const [uploadFileData, setUploadFileData] = useState({});
  const [mappingSelectionArr, setMappingSelectionArr] = useState([]);
  const [errorListModal, setErrorListModal] = useState(false)
  const [errorList, setErrorList] = useState([])
  const [uploadConfirmationModal, setUploadConfirmationModal] = useState(false)
  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [showDocProfile, setShowDocProfile] = useState(false);
  const [insertType, setInsetType] = useState('')
  // const [paramss, setParamss] = useState({});
  const [uploadedPIsMappingList, setUploadedPIsMappingList] = useState([]);
  const [checkboxList, setCheckboxList] = useState([])
  const [viewedFile, setViewedFile] = useState({});
  const [source, setSource] = useState('')
  const [updatedPIStatus, setUpdatedPIStatus] = useState({});
  const uploadSponsorsFileResponse = useSelector((store) => store.uploadSponsorsFileResponse);
  const addColumnMappingResponse = useSelector((store) => store.addColumnMappingResponse);
  const getUploadedPIsMeta = useSelector((store) => store.getUploadedPIsMeta);
  const getUploadedPIsList = useSelector((store) => store.getUploadedPIsList);
  const createPiResponse = useSelector((store) => store.createPiResponse);
  const [pisCount, setPisCount] = useState({
    total_parse: 0,
    match_found: 0,
    action_required: 0,
    total_added: 0,
    no_action: 0,
  })
  const updateUploadedPIResponse = useSelector((store) => store.updateUploadedPIResponse);
  const addUploadedPIsResponse = useSelector((store) => store.addUploadedPIsResponse);
  const [searchText, setSearchText] = useState('');
  const autoCompleteData = useSelector((state) => state.autoCompleteData);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [showTypeMore, setShowTypeMore] = useState(false);
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [selectedFromSearchPI, setSelectedFromSearchPI] = useState({});
  const [searchPIViewedFor, setSearchPIViewedFor] = useState({});
  // const [showLoader, setShowLoader] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false)

  useEffect(() => {
    dispatch(getUploadedPIsMetaAction({
      project_id: props.project_id,
    }));
  }, []);

  useEffect(() => {
    console.log('insetype', insertType)
    if (insertType === 'insert' || insertType === 'insert_2') {
      if (updateUploadedPIResponse.flag && createPiResponse.flag) {
        dispatch(getUploadedPIsListAction({
          meta_id: viewedFile?.id,
        }))
        dispatch(addPiResetAction())
        dispatch(updateUploadedPIResetAction())
        setInsetType('')
      }
      if (updateUploadedPIResponse.flag && selectedFromSearchPI !== {}) {
        dispatch(getUploadedPIsListAction({
          meta_id: viewedFile?.id,
        }))
        setSelectedFromSearchPI({})
        setCurrentStep('pisMapping')
        dispatch(updateUploadedPIResetAction())
        setInsetType('')
      }
    }
  }, [updateUploadedPIResponse]);

  useEffect(() => {
    setUploadConfirmationModal(false)
  }, [currentStep])

  useEffect(() => {
    setDisplayModal(props.displayModal);
    if (props.displayModal) {
      setCurrentStep('fileUpload')
    }
  }, [props.displayModal])

  useEffect(() => {
    if (getUploadedPIsMeta.flag) {
      setUploadedFileList(getUploadedPIsMeta.data.data);
    }
  }, [JSON.stringify(getUploadedPIsMeta)])

  useEffect(() => {
    if (uploadSponsorsFileResponse.flag) {
      if (uploadSponsorsFileResponse.data.data.columns.length) {
        setCurrentStep('columnMapping');
        setFilesList([]);
        setUploadFileData(uploadSponsorsFileResponse.data.data);
      } else {
        notify('File cannot be blank', 'warning')
        setCurrentStep('fileUpload')
      }
    }
  }, [JSON.stringify(uploadSponsorsFileResponse)])

  useEffect(() => {
    if (addColumnMappingResponse.flag) {
      dispatch(getUploadedPIsMetaAction({
        project_id: props.project_id,
      }));
      setMappingSelectionArr([]);
      setCurrentStep('fileUpload');
    }
  }, [JSON.stringify(addColumnMappingResponse)])

  useEffect(() => {
    if (getUploadedPIsList.flag) {
      setUploadConfirmationModal(false)
      setUploadedPIsMappingList(getUploadedPIsList.data.data);
      const temp = []
      getUploadedPIsList.data.data.map((uploadedPiItm) => (
        temp.push(uploadedPiItm.use_sponsor_email)
      ))
      console.log('temp====>', temp, checkboxList)
      setCheckboxList(temp)
      let total_parse = 0
      let match_found = 0
      let action_required = 0
      let total_added = 0
      let no_action = 0
      getUploadedPIsList.data.data.map((itm) => {
        total_parse += 1
        if (itm.action === null) {
          action_required += 1
        }
        if (itm.matching_id !== null) {
          match_found += 1
        }
        if (itm.action === 'insert') {
          total_added += 1
        }
        if (itm.action === 'no_action') {
          no_action += 1
        }
        return undefined
      })
      setPisCount({
        ...pisCount, total_parse, action_required, match_found, total_added, no_action,
      })
    }
  }, [JSON.stringify(getUploadedPIsList)])

  useEffect(() => {
    if (updateUploadedPIResponse.flag) {
      setSelectedFromSearchPI({});
      setSearchPIViewedFor({});
      setSearchText('');
    }
  }, [JSON.stringify(updateUploadedPIResponse)])

  useEffect(() => {
    if (addUploadedPIsResponse.flag) {
      dispatch(getUploadedPIsMetaAction({
        project_id: props.project_id,
      }));
      setCurrentStep('fileUpload');
    }
  }, [JSON.stringify(addUploadedPIsResponse)])

  useEffect(() => {
    if (autoCompleteData.flag) {
      setAutosuggestOptions(autoCompleteData.data);
    }
  }, [JSON.stringify(autoCompleteData)]);

  const handleCancel = () => {
    setDisplayModal(false);
    dispatch(uploadSponsorsFileResetAction({}))
    props.onModalClose();
  }
  const onFileChange = (e) => {
    setFilesList(e.target.files)
  }

  const onFileUpload = () => {
    const params = {
      project_id: props.project_id,
      analysis_id: '74b0bj54wldbf26x6',
      list_source: source,
    };
    const formData = new FormData()
    formData.append('upfile', filesList[0]);

    dispatch(uploadSponsorsFileAction({
      params,
      body: formData,
    }))
  }

  const onMappingDropdownSelection = (landscapeItm, mappingItm) => {
    const tempArr = [...mappingSelectionArr];
    if (tempArr.filter((itm) => itm.sheet_header === mappingItm).length) {
      tempArr[tempArr.findIndex((mapping) => mapping.sheet_header === mappingItm)].mapped_key = landscapeItm.value;
    } else {
      tempArr.push({
        landScapeTitle: landscapeItm.key,
        sheet_header: mappingItm,
        mapped_key: landscapeItm.value,
      })
    }
    setMappingSelectionArr(tempArr);
  }

  const onFileItemClick = (file) => {
    setCurrentStep('pisMapping');
    setViewedFile(file);
    dispatch(getUploadedPIsListAction({
      meta_id: file.id,
    }))
  }

  const onPIActionChange = (value, piObj, searchedPI) => {
    const tempObj = { ...updatedPIStatus };
    tempObj[piObj.innoplexus_id] = value === 'insert_2' ? '' : value;
    setUpdatedPIStatus(tempObj);
    dispatch(updateUploadedPIAction({
      params: {
        innoplexus_id: piObj.innoplexus_id,
      },
      body: {
        action: value === 'insert_2' || value === 'insert_1' ? 'insert' : value,
        selected_id: value === 'insert_1' ? piObj.matching_id : value === 'insert_2' ? searchedPI.id : '',
        status: value === 'no_action' ? 'done' : undefined,
        file_name: viewedFile.file_name,
        add_req_iid: '',
      },
    }, {
      key: piObj.innoplexus_id,
    }))
  }

  const getFileStatusValue = (file) => {
    if (file.status === 'done') {
      return 'Completed';
    }
    if (file.status === 'in_progress') {
      if (file.nn_status === 'done') {
        if (file.error_details.length) {
          if (file.error_details[0]?.length === 0) {
            return 'Ready for PIs selection';
          }
        } else if (file.error_details.length === 0) {
          return 'Partially ready for PIs selection';
        }
        return 'Ready for PIs selection';
      }
      if (file.nn_status === 'in_progress') {
        if (file.mapping && file.mapping.length) {
          return 'Analyzing uploaded file';
        }
        if (file.mapping.length === 0) {
          return 'Attribute mapping pending';
        }
      }
      if (file.nn_status === 'error') {
        return 'Error in analyzing the file';
      }
    }
    if (file.status === 'error') {
      return 'System error, upload the file again';
    }
    return '-';
  }

  const onChangeInput = (e) => {
    dispatch(getAutocompleteValuesCancelAction());
    if (e.target.value && e.target.value.trim().length <= 2) {
      setDisplayDropdown(true);
      setShowTypeMore(true);
      setSearchText(e.target.value);
      dispatch(getAutocompleteValuesResetAction());
    } else if (e.target.value && e.target.value.trim().length > 2 && !emojiRegex.test(e.target.value)) {
      setShowTypeMore(false);
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      dispatch(
        getAutocompleteValuesAction({
          query: e.target.value.trim(),
        }),
      );
    } else {
      setDisplayDropdown(false);
      setSearchText('');
    }
  };

  const onSearchPISelect = (piObj) => {
    if (selectedFromSearchPI.id === piObj.id) {
      setSelectedFromSearchPI({});
      setSearchText('');
    } else {
      setSelectedFromSearchPI(piObj);
      setSearchText(piObj.name);
    }
    setTimeout(() => {
      setDisplayDropdown(false);
    }, 6000);
  }

  const checkColMappingDone = () => {
    let temp = false;
    if (mappingSelectionArr.length === 0) {
      return true;
    }
    requiredMapFields.forEach((itm) => {
      const temp1 = mappingSelectionArr.filter((mappingItm) => mappingItm.mapped_key === itm)
      if (temp1.length === 0) {
        temp = true
      }
    })
    return temp;
  }

  const renderAutosuggestOptions = () => {
    if (autoCompleteData.flag && autosuggestOptions.length) {
      return autosuggestOptions.map((itm, index) => {
        if (itm.type === 'PI') {
          return (
            <div
              className="search-dropdown-result-list-card"
              key={index}
            >
              <div className="card-radio-wrap">
                <Radio checked={selectedFromSearchPI.id === itm.id} onClick={() => onSearchPISelect(itm)} />
              </div>
              {getTemplate(itm.type, itm, true)}
            </div>
          );
        }
        return null;
      });
    }
    if (autoCompleteData.flag && autosuggestOptions.length === 0) {
      return (
        <div className="autosuggest-no-data-msg">
          <span>
            No matches found, click &#39;Search&#39; to initiate plain text search.
          </span>
        </div>
      );
    }
    if (showTypeMore) {
      return (
        <div className="autosuggest-no-data-msg">
          Please type at least 3 characters to show autosuggested options or initiate search.
        </div>
      );
    }
    return null;
  };

  const refreshFileData = () => {
    dispatch(getUploadedPIsMetaAction({
      project_id: props.project_id,
    }));
  }

  const handleCheckboxChange = (e, piObj, index) => {
    console.log(e)
    const temp2 = checkboxList
    temp2[index] = e.target.checked;
    setCheckboxList(temp2)
    dispatch(updateUploadedPIAction({
      params: {
        innoplexus_id: piObj.innoplexus_id,
      },
      body: {
        use_sponsor_email: e.target.checked,
      },
    }, {
      key: piObj.innoplexus_id,
    }))
  }

  const onSearch = (e) => {
    setDisplayDropdown(false);
    setShowDocProfile(true)
    dispatch(getDatasetListResetAction())
    // if (e && e.length > 2) {
    //   const paramssed = {
    //     query: e,
    //     searchfield: 'investigators',
    //     currentTab: 'investigators',
    //   };
    //   setParamss(paramssed);
    // } else {
    //   const paramssed = {
    //     query: '',
    //     searchfield: 'investigators',
    //     currentTab: 'investigators',
    //   };
    //   setParamss(paramssed);
    // }
    dispatch(
      getDatasetListDataAction({
        query: e,
        dataset: 'investigators',
      }),
    );
  };

  const isSameNameFile = () => {
    let alreadyArray = []
    if (filesList.length) {
      const fileName = filesList[0]?.name
      if (getUploadedPIsMeta.flag && uploadedFileList.length) {
        alreadyArray = uploadedFileList.filter((file) => fileName === file.file_name)
      }
    }
    return alreadyArray.length > 0
  }

  const isEmptyFile = () => {
    if (uploadSponsorsFileResponse.data.data) {
      if (uploadSponsorsFileResponse.data.data.statusCode === 409) {
        return true
      }
      return false
    }
    return false
  }
  const renderModalContent = () => {
    switch (currentStep) {
      case 'fileUpload':
        return (
          <div className={`uploade-sec-list ${filesList.length ? 'with-upload' : 'without-upload'}`}>
            <div className="upload-wrap">
              {!filesList.length
                ? (
                  <div className="uploaded-file-wrap">
                    <div className="uploade-list-content">
                      <Radio.Group value={source} onChange={(e) => setSource(e.target.value)}>
                        <div className="upl-list-source-section">
                          <div className="upl-list-source-text">
                            Source:
                          </div>
                          <div className="upl-list-source-radio">
                            <Radio value="LCOR">LCOR</Radio>
                          </div>
                          <div className="upl-list-source-radio">
                            <Radio value="Sponsor">Sponsor</Radio>
                          </div>
                        </div>
                      </Radio.Group>
                      <label className="upload-list-project">Upload List</label>
                      <input
                        style={{ color: 'transparent' }}
                        disabled={source === ''}
                        id="file-input-btn"
                        className="file-input-btn"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={onFileChange}
                      />
                    </div>
                  </div>
                )
                : (
                  <div className="uploaded-file-wrap">
                    <div className="upl-list-title">
                      Upload List
                    </div>
                    {filesList.length && !isSameNameFile() && !isEmptyFile()
                      ? (
                        <div className="next-btn">
                          <Button type="primary" disabled={uploadSponsorsFileResponse.loading} onClick={() => onFileUpload()}>Next</Button>
                        </div>
                      )
                      : isSameNameFile()
                        ? (<div className="error-msg-sponsor-upload"> File with the same name already exists.</div>)
                        : isEmptyFile()
                          ? (<div className="error-msg-sponsor-upload"> Cannot upload blank file.</div>)
                          : null}
                    <div className="uploaded-file-wrap-name">
                      {filesList[0].name}
                      <div
                        className="delete-icon"
                        role="presentation"
                        onClick={() => {
                          dispatch(uploadSponsorsFileResetAction({}));
                          setFilesList([])
                        }}
                      />
                    </div>
                  </div>
                )}
            </div>
            <div className="upload-sponsors-content-wrap">
              <div className="in-progress-files-wrap">
                <div className="files-wrap-tabs">
                  <div className="files-wrap-tabs-in in-progress-title">Previously uploaded files</div>
                  <div className="files-wrap-tabs-in refresh-btn" role="presentation" onClick={() => refreshFileData()}>
                    <div className="files-wrap-tabs-in refresh-btn  refresh-icon">
                      <div className="refresh-icon-img" />
                    </div>
                  </div>
                  {
                    // <div className="files-wrap-tabs-in done-title">Done</div>
                  }
                </div>
                <div className="in-progress-files-list ">
                  <Loader loading={getUploadedPIsMeta.loading} error={getUploadedPIsMeta.error}>
                    <div className="file-list-header">
                      <div className="file-name-header">File name</div>
                      <div className="file-source-header">File Source</div>
                      <div className="file-status-header">Status</div>
                      <div className="file-meta-header" />
                    </div>
                    <>
                      {getUploadedPIsMeta.flag && uploadedFileList.length
                        ? (
                          <div className="progress-file-wrap">
                            {uploadedFileList.map((file, i) => (
                              <div className="in-progress-files-list-itm-wrap" key={i}>
                                <div className="file-name">{file.file_name}</div>
                                <div className="file-source">{file.list_source ? file.list_source : '-'}</div>
                                <div className="file-status">{getFileStatusValue(file)}</div>
                                <div
                                  className={`icons-wrap error-icon ${file.nn_status === 'done' && (file.status === 'in_progress' || file.status === 'done') && !(file?.error_details === null || file?.error_details.length === 0 || (file?.error_details.length && file?.error_details[0].length === 0)) ? '' : 'disabled'}`}
                                  role="presentation"
                                  onClick={() => {
                                    if (file.nn_status === 'done' && (file.status === 'in_progress' || file.status === 'done') && !(file?.error_details === null || file?.error_details.length === 0 || (file?.error_details.length && file?.error_details[0].length === 0))) {
                                      setErrorList(file.error_details)
                                      setErrorListModal(true)
                                    }
                                  }}
                                  title="Error list in uploaded file"
                                >
                                  <div className="error-icon-img" />
                                </div>
                                <div
                                  className={`icons-wrap info-icon ${file.nn_status === 'in_progress' && file.status === 'in_progress' && file.mapping.length === 0 ? '' : 'disabled'}`}
                                  role="presentation"
                                  onClick={() => {
                                    if (file.nn_status === 'in_progress' && file.status === 'in_progress' && file.mapping.length === 0) {
                                      setCurrentStep('columnMapping');
                                      setFilesList([]);
                                      setUploadFileData(file);
                                    }
                                  }}
                                  title="Mapping of fields pending! Please click here to complete it"
                                >
                                  <div className="info-icon-img" />
                                </div>
                                <div
                                  className={`icons-wrap view-icon ${file.nn_status === 'done' && (file.status === 'in_progress' || file.status === 'done') ? '' : 'disabled'}`}
                                  role="presentation"
                                  onClick={() => {
                                    if (file.nn_status === 'done' && (file.status === 'in_progress' || file.status === 'done')) {
                                      onFileItemClick(file);
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        )
                        : null}
                    </>
                    {
                      errorListModal && (
                        <Modal
                          visible={errorListModal}
                          onCancel={() => setErrorListModal(false)}
                          title="Error List"
                          cancelButtonProps={{ style: { display: 'none' } }}
                          width="750px"
                          okButtonProps={{ style: { display: 'none' } }}
                        >
                          {errorList.length && errorList[0].map((error) => <div className="error-list-item">{`${error}\n`}</div>)}
                        </Modal>
                      )
                    }
                  </Loader>
                </div>
              </div>
            </div>
          </div>
        )

      case 'columnMapping':
        return (
          <div className="upload-sponsor-content">
            <div className="sponsor-modal-back-btn" role="presentation" onClick={() => setCurrentStep('fileUpload')}>
              Back
            </div>
            <div className="column-mapping-wrap">
              <div className="column-mapping-header">
                <div className="column-mapping-title">LandSCAPE</div>
                <div className="column-mapping-title">Excel field</div>
              </div>
              <div className="column-mapping-content">
                {uploadFileData.columns && uploadFileData.columns.length
                  ? (
                    <>
                      {uploadFileData.map_fields.map((mapField, index) => (
                        <div className="column-mapping-itm-wrap" key={index}>
                          <div className="mapping-itm excel-field-name" title={mapField.key}>
                            {mapField.key}
                            {requiredMapFields.indexOf(mapField.value) !== -1
                              ? (
                                <span className="asterisk-red">*</span>
                              )
                              : null}
                          </div>
                          <div className="middle-wrap">
                            <div className="middle-wrap-img" />
                          </div>
                          <div className="mapping-itm mapping-dropdown-wrap">
                            <Select popupClassName="sortby-single-select-dropdown" value={mappingSelectionArr.filter((itm) => itm.mapped_key === mapField.value)[0]?.sheet_header || ''} style={{ width: 350 }} onChange={(val) => onMappingDropdownSelection(mapField, val)}>
                              {uploadFileData.columns.map((mappingItm, ind) => (
                                <Option value={mappingItm} title={mappingItm} key={ind}>
                                  {mappingItm}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      ))}
                    </>
                  )
                  : null}
              </div>
              <div className="column-mapping-btns-wrap">
                <Button
                  className="cancel-wrapper cancel-btn ant-btn-default"
                  onClick={() => setMappingSelectionArr([])}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  className="save-wrapper primary-btn ant-btn-primary"
                  disabled={checkColMappingDone()}
                  onClick={() => {
                    setCurrentStep('columnMappingConfirm');
                    // dispatch(addColumnMappingAction({
                    //   params: {
                    //     meta_id: uploadFileData.meta_id,
                    //   },
                    //   body: mappingSelectionArr,
                    // }))
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
        )

      case 'columnMappingConfirm':
        return (
          <div className="upload-sponsor-content">
            <div className="sponsor-modal-back-btn" role="presentation" onClick={() => setCurrentStep('fileUpload')}>
              Back
            </div>
            <div className="column-mapping-wrap">
              <div className="column-mapping-header column-mapping-header-table">
                <div className="column-mapping-title">LandSCAPE</div>
                <div className="column-mapping-title">Excel field</div>
              </div>
              <div className="column-mapping-content">
                <div className="column-mapping-table-wrap">
                  {mappingSelectionArr.map((mapping, index) => {
                    return (
                      <div className="column-mapping-table-itm" key={index}>
                        <div className="left-side-itm">{mapping.landScapeTitle}</div>
                        <div className="middle-wrap">
                          <div className="middle-wrap-img" />
                        </div>
                        <div className="right-side-itm">{mapping.sheet_header}</div>
                      </div>
                    )
                  })}
                </div>
                <div className="column-mapping-btns-wrap">
                  <Button
                    className="cancel-wrapper cancel-btn ant-btn-default"
                    onClick={() => setCurrentStep('columnMapping')}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="save-wrapper primary-btn ant-btn-primary"
                    disabled={checkColMappingDone() || addColumnMappingResponse.loading}
                    onClick={() => {
                      dispatch(addColumnMappingAction({
                        params: {
                          meta_id: uploadFileData.meta_id,
                        },
                        body: mappingSelectionArr,
                      }))
                    }}
                  >
                    Confirm <Spin spinning={addColumnMappingResponse.loading} style={{ paddingLeft: '10px' }} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );

      case 'pisMapping':
        return (
          <>
            <div className="upload-sponsor-content">
              <div className="sponsor-modal-back-btn" role="presentation" onClick={() => setCurrentStep('fileUpload')}>
                Back
              </div>
              <div className="pi-mapping-wrap">
                <div className="pi-mapping-header">
                  <div className="pi-mapping-header-itm">Sponsor provided investigators</div>
                  <div className="pi-mapping-header-itm">Match found in LandSCAPE</div>
                  <div className="pi-mapping-header-itm">Action</div>
                </div>
                <Loader loading={getUploadedPIsList.loading} error={getUploadedPIsList.error}>
                  <div className="pi-mapping-list-wrap">
                    {getUploadedPIsList.flag && uploadedPIsMappingList.length
                      ? (
                        <>
                          {uploadedPIsMappingList.map((uploadedPiItm, index) => (
                            <div className={`pi-mapping-itm-wrap ${uploadedPiItm.matching_id ? 'match-found' : 'no-match-found'}`} key={index}>
                              <div className="sponsor-itm-card-wrap">
                                <div className="landscape-itm-card card">
                                  <div className="card-name">
                                    <div className="card-name-title">{`${uploadedPiItm.first_name} ${uploadedPiItm.last_name}`}</div>
                                    {
                                      // <div className="dropdown-title">
                                      //   <Select disabled={updateUploadedPIResponse.data[uploadedPiItm.innoplexus_id] || uploadedPiItm.action} dropdownClassName="pi-mapping-single-select-dropdown" value={updatedPIStatus[uploadedPiItm.innoplexus_id] || uploadedPiItm.action} style={{ width: 250 }} onChange={(val) => onSponsorPIActionChange(val, uploadedPiItm)}>
                                      //     {sponsorsPiActionOpts.map((piActOpt, ind) => (
                                      //       <Option value={piActOpt.value} title={piActOpt.key} key={ind}>
                                      //         {piActOpt.key}
                                      //       </Option>
                                      //     ))}
                                      //   </Select>
                                      // </div>
                                    }
                                  </div>
                                  <div className="card-email-phone-wrap">
                                    {uploadedPiItm.email && uploadedPiItm.email.length
                                      ? (
                                        <div className="email">&nbsp;{uploadedPiItm.email.join('; ')}</div>
                                      )
                                      : null}
                                    {uploadedPiItm.phone_number && uploadedPiItm.phone_number.length
                                      ? (
                                        <div className="phone">&nbsp;{uploadedPiItm.phone_number.join('; ')}</div>
                                      )
                                      : null}
                                  </div>
                                  <div className="card-email-phone-wrap">
                                    <div className="affiliated-site">{uploadedPiItm.primary_organization.name}</div>
                                  </div>
                                  {uploadedPiItm.indications && uploadedPiItm.indications.length
                                    ? (
                                      <div className="card-indications">
                                        <div className="lbl">Indications:&nbsp;</div>
                                        <div className="indications">{uploadedPiItm.indications.join('; ')}</div>
                                      </div>
                                    )
                                    : null}
                                </div>
                              </div>
                              <div className="landscape-itm-card-wrap">
                                {(uploadedPiItm.selected_id && (uploadedPiItm.selected_id !== uploadedPiItm.matching_id))
                                  ? (
                                    <div className="landscape-itm-card card">
                                      <div className="card-name">
                                        <div className="card-name-title">
                                          {uploadedPiItm.selected_document.name}
                                        </div>
                                      </div>
                                      <div className="card-email-phone-wrap">
                                        {uploadedPiItm.selected_document.email && uploadedPiItm.selected_document.email.length
                                          ? (
                                            <div className="email">&nbsp;{uploadedPiItm.selected_document.email.join('; ')}</div>
                                          )
                                          : null}
                                        {uploadedPiItm.selected_document.phone_number && uploadedPiItm.selected_document.phone_number.length
                                          ? (
                                            <div className="phone">&nbsp;{uploadedPiItm.selected_document.phone_number.join('; ')}</div>
                                          )
                                          : null}
                                      </div>
                                      <div className="card-email-phone-wrap">
                                        <div className="affiliated-site">{uploadedPiItm.selected_document.affiliated_site}</div>
                                      </div>
                                      {uploadedPiItm.selected_document.indications && uploadedPiItm.selected_document.indications.length
                                        ? (
                                          <div className="card-indications">
                                            <div className="lbl">Indications:&nbsp;</div>
                                            <div className="indications">{uploadedPiItm.selected_document.indications.join('; ')}</div>
                                          </div>
                                        )
                                        : null}
                                    </div>
                                  )
                                  : uploadedPiItm.matching_id
                                    ? (
                                      <div className="landscape-itm-card card">
                                        <div className="card-name">
                                          <div className="card-name-title">
                                            {uploadedPiItm.mached_document.name}
                                          </div>
                                        </div>
                                        <div className="card-email-phone-wrap">
                                          {uploadedPiItm.mached_document.email && uploadedPiItm.mached_document.email.length
                                            ? (
                                              <div className="email">&nbsp;{uploadedPiItm.mached_document.email.join('; ')}</div>
                                            )
                                            : null}
                                          {uploadedPiItm.mached_document.phone_number && uploadedPiItm.mached_document.phone_number.length
                                            ? (
                                              <div className="phone">&nbsp;{uploadedPiItm.mached_document.phone_number.join('; ')}</div>
                                            )
                                            : null}
                                        </div>
                                        <div className="card-email-phone-wrap">
                                          <div className="affiliated-site">{uploadedPiItm.mached_document.affiliated_site}</div>
                                        </div>
                                        {uploadedPiItm.mached_document.indications && uploadedPiItm.mached_document.indications.length
                                          ? (
                                            <div className="card-indications">
                                              <div className="lbl">Indications:&nbsp;</div>
                                              <div className="indications">{uploadedPiItm.mached_document.indications.join('; ')}</div>
                                            </div>
                                          )
                                          : null}
                                      </div>
                                    )
                                    : (
                                      <div className="landscape-itm-card card no-match">
                                        <div className="no-match-text">No match found</div>
                                      </div>
                                    )}
                              </div>
                              <div className="last-col-sec">
                                <Select
                                  // disabled={updateUploadedPIResponse.data[uploadedPiItm.innoplexus_id] || uploadedPiItm.action}
                                  disabled={viewedFile.status === 'done'}
                                  popupClassName="pi-mapping-single-select-dropdown"
                                  value={updatedPIStatus[uploadedPiItm.innoplexus_id] || (uploadedPiItm.action === 'insert' && uploadedPiItm.selected_id !== '' && uploadedPiItm.selected_id !== uploadedPiItm.matching_id ? '' : uploadedPiItm.action === 'insert' && uploadedPiItm.selected_id !== '' ? 'insert_1' : uploadedPiItm.action)}
                                  style={{ width: 250 }}
                                  onChange={(val) => {
                                    setInsetType(val)
                                    if (val === 'insert_2') {
                                      setSearchPIViewedFor(uploadedPiItm);
                                      setCurrentStep('searchView')
                                    } else if (val === 'insert') {
                                      setIsAddPiModalOpen(true)
                                      setPiObjforAdd(uploadedPiItm)
                                    } else {
                                      onPIActionChange(val, uploadedPiItm)
                                    }
                                  }}
                                >
                                  {sponsorsPiActionOpts.map((piActOpt, ind) => (
                                    <Option
                                      disabled={(ind === 0 && !uploadedPiItm.matching_id)}
                                      value={piActOpt.value}
                                      title={piActOpt.key}
                                      key={piActOpt.value}
                                    >
                                      {piActOpt.key}
                                    </Option>
                                  ))}
                                </Select>
                                {
                                uploadedPiItm?.email && uploadedPiItm?.email?.length && uploadedPiItm.matching_id
                                  ? (
                                    <div className="checkbox-section">
                                      <Checkbox defaultChecked={checkboxList[index]} disabled={viewedFile.status === 'done'} onChange={(e) => handleCheckboxChange(e, uploadedPiItm, index)}> Leverage Email IDs in Spreadsheet For Investigator Profiles? </Checkbox>
                                    </div>
                                ) : undefined
                                }
                              </div>
                            </div>
                          ))}
                        </>
                      )
                      : null}
                  </div>
                </Loader>
                <div className="column-mapping-btns-wrap">
                  <Button
                    type="primary"
                    className="save-wrapper primary-btn ant-btn-primary"
                    // disabled={getUploadedPIsList.loading || (uploadedPIsMappingList.filter((itm) => itm.action !== null).length + Object.keys(updatedPIStatus).length) !== uploadedPIsMappingList.length}
                    disabled={getUploadedPIsList.loading || viewedFile.status === 'done' || (uploadedPIsMappingList.filter((itm) => itm.action !== null).length + Object.keys(updatedPIStatus).length) === 0}
                    onClick={() => {
                      setCurrentStep('fileUpload')
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="primary"
                    className="save-wrapper primary-btn ant-btn-primary"
                    // disabled={getUploadedPIsList.loading || (uploadedPIsMappingList.filter((itm) => itm.action !== null).length + Object.keys(updatedPIStatus).length) !== uploadedPIsMappingList.length}
                    disabled={getUploadedPIsList.loading || viewedFile.status === 'done' || (uploadedPIsMappingList.filter((itm) => itm.action !== null).length + Object.keys(updatedPIStatus).length) === 0}
                    onClick={() => {
                      setUploadConfirmationModal(true)
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
            {uploadConfirmationModal && (
              <Modal
                visible={uploadConfirmationModal}
                title="Confirmation"
                onCancel={() => setUploadConfirmationModal(false)}
                onOk={() => dispatch(addUploadedPIsAction({
                  meta_id: viewedFile.id,
                }))}
                okText="Confirm"
              >
                <div className="error-list-item">
                  Are you sure you want to submit the changes for the entire file? Once submitted, you will not be able to make more changes for any records within this file. Please confirm by clicking Confirm.
                </div>
              </Modal>
            )}
            {isAddPiModalOpen && (
              <Modal
                title="Add New Investigator"
                open={isAddPiModalOpen}
                onCancel={() => { setIsAddPiModalOpen(false) }}
                className="modal-wrapper add-investigator-modal"
                maskClosable={false}
                destroyOnClose
                footer={null}
              >
                <div className="add-investigator-section add-investigator-section-lvl-up">
                  <AddInvestigator
                    flag="savedAnalysis"
                    type="UploadSponsor"
                    setModal={setIsAddPiModalOpen}
                    piObj={piObjforAdd}
                    analysisId={props.project_id}
                    viewedFile={viewedFile}
                  />
                </div>
              </Modal>
            )}
          </>
        )

      case 'searchView':
        return (
          <div className="add-investigator-modal-search">
            <Loader loading={updateUploadedPIResponse.loading} error={false}>
              <div className="add-investigator-modal-search-title">Search the investigator to be added to the Project</div>
              <div className="search-bar search-wrap">
                <div className="search-bar-wrap">
                  <div className={`search-box ${searchText.trim().length >= 3 ? 'activesearch' : ''}`}>
                    <Search
                      placeholder="Search by investigator name"
                      allowClear
                      value={searchText}
                      loading={autoCompleteData.loading}
                      enterButton={(<Button disabled={searchText.trim().length <= 2}>Search investigator</Button>)}
                      size="large"
                      maxLength="256"
                      onSearch={onSearch}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
                <div className="search-dropdown-result-content">
                  {displayDropdown ? (
                    <OutsideClick
                      ignoreClickWithinClass="search-bar"
                      onClickOutside={() => {
                        setDisplayDropdown(false);
                      }}
                    >
                      <div className="search-dropdown-result">
                        <div className="search-dropdown-result-list">
                          <Loader loading={autoCompleteData.loading} error={autoCompleteData.error}>
                            {renderAutosuggestOptions()}
                          </Loader>
                        </div>
                      </div>
                    </OutsideClick>
                  ) : null}
                </div>
              </div>
              <div className="inner-row-search-data">
                {showDocProfile && (
                  <div className="whole-search-page">
                    <div className="middle-part">
                      <div className="searchbar-data">
                        <div className="search-result">
                          <Loader
                            loading={countsData.loading || datasetListData.loading}
                            error={datasetListData.error}
                          >
                            <DocumentList
                              activeDataset="investigators"
                              query={searchText}
                              IsModalProps
                              analysisId={props.project_id}
                              fromUploadSponsor
                              setSelectedFromSearchPI={setSelectedFromSearchPI}
                              selectedFromSearchPI={selectedFromSearchPI}
                            // modalStatus={modalProps.setsearchPi}
                            />
                          </Loader>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="searchView-btns-wrap">
                <Button
                  className="clear-btn"
                  onClick={() => {
                    setSearchPIViewedFor({});
                    setSearchText('')
                    dispatch(getDatasetListResetAction())
                    setShowDocProfile(false)
                    setSelectedFromSearchPI({})
                    setCurrentStep('pisMapping')
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="apply-btn"
                  type="primary"
                  onClick={() => {
                    // setShowLoader(true);
                    onPIActionChange('insert_2', searchPIViewedFor, selectedFromSearchPI);
                    // setTimeout(() => {
                    //   setCurrentStep('pisMapping');
                    //   dispatch(getUploadedPIsListAction({
                    //     meta_id: viewedFile.id,
                    //   }));
                    //   setShowLoader(false);
                    // }, 3000)
                  }}
                  disabled={!selectedFromSearchPI.id}
                >
                  Add to mapping
                </Button>
              </div>
            </Loader>
          </div>
        )
      default: return null;
    }
  }

  return (
    <>
      <Modal
        title={
          (
            <div className="sponsor-modal-header">
              <div className="sponsor-modal-title">Upload list</div>
              {
                currentStep === 'pisMapping'
                && (
                  <div className="summary-sec">
                    <div
                      className="summary-icon"
                      role="presentation"
                      onClick={() => {
                        setSummaryModal(true)
                      }}
                      title="View sponsor list summary"
                    />
                  </div>
                )
              }
            </div>
          )
        }
        visible={displayModal}
        onCancel={handleCancel}
        className="modal-wrapper add-investigator-modal upload-sponsor-list-modal"
        maskClosable={false}
        destroyOnClose
        footer={null}
      >
        {renderModalContent()}
      </Modal>
      <Modal
        title="Sheet Summary"
        visible={summaryModal}
        onCancel={() => setSummaryModal(false)}
        onOk={() => setSummaryModal(false)}
        cancelButtonProps={{
          style: { display: 'none' },
        }}
      >
        <Loader loading={getUploadedPIsList.loading} error={getUploadedPIsList.error}>
          <div className="error-list-item"># Investigators in uploaded spreadsheet : {pisCount?.total_parse}</div>
          <div className="error-list-item"># Investigators found in LandSCAPE : {pisCount?.match_found}</div>
          <div className="error-list-item"># Investigators added in Project: {pisCount?.total_added}</div>
          <div className="error-list-item"># Investigators not acted upon : {pisCount?.action_required}</div>
          <div className="error-list-item"># Investigators marked as &ldquo;No Action&ldquo; : {pisCount?.no_action}</div>
        </Loader>
      </Modal>
    </>
  )
}

export default UploadSponsorsList;
