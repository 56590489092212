import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { getNetworkDetailsAction } from './logic';
import Loader from '../../../common/components/Loader';

const NetworkDetailsInfo = () => {
  const type = 'investigators';
  const dispatch = useDispatch();
  const [networkMetaDetails, setNetworkMetaDetails] = useState({});

  const networkData = useSelector((store) => store.getNetworkDetails);

  useEffect(() => {
    const network_id = parse(location.search).id;
    const from = 1;
    const size = 10;
    const filters = JSON.stringify({ parent_flag: false });

    dispatch(
      getNetworkDetailsAction({
        type,
        network_id,
        from,
        size,
        filters,
      }),
    );
  }, []);

  useEffect(() => {
    if (networkData.flag) {
      setNetworkMetaDetails(networkData.data.data.network_details);
    }
  }, [JSON.stringify(networkData)]);
  return (
    <>
      <Loader loading={networkData.loading} error={networkData.error}>
        <div className="network-details-card card">
          <div className="project-info-content">
            <div className="project-info-content-title">Network details</div>

            <div className="content-info">
              <div className="content-info-title">Network Name</div>
              <div className="content-info-desc">{networkMetaDetails.name}</div>
            </div>
            <div className="content-info">
              <div className="content-info-title">Indication</div>
              <div className="content-info-desc">{networkMetaDetails?.indications?.length !== 0 ? networkMetaDetails?.indications?.join('; ') : '-'}</div>
            </div>
            <div className="content-info">
              <div className="content-info-title">Network Type</div>
              <div className="content-info-desc">{networkMetaDetails.network_affiliation !== '' ? networkMetaDetails.network_affiliation : '-'}</div>
            </div>
            <div className="content-info">
              <div className="content-info-title">Therapy Area</div>
              <div className="content-info-desc">
                {networkMetaDetails?.therapy_area?.length !== 0 ? networkMetaDetails?.therapy_area?.join('; ') : '-'}
              </div>
            </div>
            {/* {networkMetaDetails?.network_affiliation !== '' && (
            <div className="content-info">
              <div className="content-info-title">Network Type</div>
              <div className="content-info-desc">{networkMetaDetails?.network_affiliation}</div>
            </div>
            )} */}
            <div className="content-info">
              <div className="content-info-title">Description</div>
              <div className="content-info-desc">{networkMetaDetails?.description !== '' ? networkMetaDetails?.description : '-'}</div>
            </div>
            {/* <div className="content-info">
              <div className="content-info-title">Address</div>
              <div className="content-info-desc">{networkMetaDetails?.address?.address_line_1}</div>
              <div className="content-info-desc">{networkMetaDetails?.address?.address_line_2}</div>
              <div className="content-info-desc">{networkMetaDetails?.address?.city}</div>
              <div className="content-info-desc">{networkMetaDetails?.address?.state}</div>
              <div className="content-info-desc">{networkMetaDetails?.address?.zip}</div>
            </div> */}
            {/* {networkMetaDetails?.legal_entity !== '' && (
              <div className="content-info">
                <div className="content-info-title">Legal entity</div>
                <div className="content-info-desc">{networkMetaDetails?.legal_entity}</div>
              </div>
            )} */}
            {/* {networkMetaDetails?.payment_structure !== '' && (
              <div className="content-info">
                <div className="content-info-title">Payment structure</div>
                <div className="content-info-desc">{networkMetaDetails?.payment_structure}</div>
              </div>
            )} */}
            {/* {networkMetaDetails?.percent_overhead_charged !== 0 && (
              <div className="content-info">
                <div className="content-info-title">Legal entity</div>
                <div className="content-info-desc">
                  {networkMetaDetails?.percent_overhead_charged}
                </div>
              </div>
            )} */}
            {/* {networkMetaDetails?.survey_email !== '' && (
              <div className="content-info">
                <div className="content-info-title">Survey Email Address</div>
                <div className="content-info-desc">{networkMetaDetails?.survey_email}</div>
              </div>
            )} */}
            {/* {networkMetaDetails?.network_type?.embedded_site_model !== '' && (
              <div className="content-info">
                <div className="content-info-title">Embedded site model</div>
                <div className="content-info-desc">
                  {networkMetaDetails?.network_type?.embedded_site_model}
                </div>
              </div>
            )} */}

            {/* {networkMetaDetails?.network_type?.central_feasibility !== '' && (
              <div className="content-info">
                <div className="content-info-title">Central feasibility</div>
                <div className="content-info-desc">
                  {networkMetaDetails?.network_type?.central_feasibility}
                </div>
              </div>
            )} */}
            {/* {networkMetaDetails?.network_type?.central_budget !== '' && (
              <div className="content-info">
                <div className="content-info-title">Central budget, central contract</div>
                <div className="content-info-desc">
                  {networkMetaDetails?.network_type?.central_budget}
                </div>
              </div>
            )} */}
            {/* {networkMetaDetails?.network_type?.site_contracts !== '' && (
              <div className="content-info">
                <div className="content-info-title">Site contracts, central assistance only</div>
                <div className="content-info-desc">
                  {networkMetaDetails?.network_type?.site_contracts}
                </div>
              </div>
            )} */}

            {/* {networkMetaDetails?.network_type?.site_payment_support !== '' && (
              <div className="content-info">
                <div className="content-info-title">Site payment support</div>
                <div className="content-info-desc">
                  {networkMetaDetails?.network_type?.site_payment_support}
                </div>
              </div>
            )} */}

            {/* {networkMetaDetails?.network_type?.centrally_coordinated_patient_recruitment_services
              !== '' && (
              <div className="content-info">
                <div className="content-info-title">
                  Centrally coordinated patient recruitment services
                </div>
                <div className="content-info-desc">
                  {
                    networkMetaDetails?.network_type
                      ?.centrally_coordinated_patient_recruitment_services
                  }
                </div>
              </div>
            )} */}
            {/* {networkMetaDetails?.network_type?.project_management !== '' && (
              <div className="content-info">
                <div className="content-info-title">Project management</div>
                <div className="content-info-desc">
                  {networkMetaDetails?.network_type?.project_management}
                </div>
              </div>
            )} */}
            {/* {networkMetaDetails?.network_type?.provision_of_equipment !== '' && (
              <div className="content-info">
                <div className="content-info-title">Provision of equipment</div>
                <div className="content-info-desc">
                  {networkMetaDetails?.network_type?.provision_of_equipment}
                </div>
              </div>
            )} */}
            {/* {networkMetaDetails?.network_type?.provision_of_equipment_description !== '' && (
              <div className="content-info">
                <div className="content-info-title">
                  Provision of equipment description
                </div>
                <div className="content-info-desc">
                  {networkMetaDetails?.network_type
                    ?.provision_of_equipment_description}
                </div>
              </div>
            )} */}
            {/* {networkMetaDetails?.feasibility_contact_details
              ?.name !== '' && (
              <div className="content-info">
                <div className="content-info-title">
                  Feasibility contact person name
                </div>
                <div className="content-info-desc">
                  {networkMetaDetails
                    ?.feasibility_contact_details?.name}
                </div>
              </div>
                )} */}
            {/* {networkMetaDetails?.feasibility_contact_details
              ?.phone_number !== '' && (
              <div className="content-info">
                <div className="content-info-title">
                  Feasibility contact person contact number
                </div>
                <div className="content-info-desc">
                  {networkMetaDetails
                    ?.feasibility_contact_details?.phone_number}
                </div>
              </div>
                )} */}
            {/* {networkMetaDetails?.feasibility_contact_details
              ?.email !== '' && (
              <div className="content-info">
                <div className="content-info-title">
                  Feasibility contact person contact email
                </div>
                <div className="content-info-desc">
                  {networkMetaDetails
                    ?.feasibility_contact_details?.email}
                </div>
              </div>
                )} */}
            {/* {networkMetaDetails?.feasibility_process !== '' && (
            <div className="content-info">
              <div className="content-info-title">Feasibility process</div>
              <div className="content-info-desc">
                {networkMetaDetails?.feasibility_process}
              </div>
            </div>
            )} */}
          </div>
        </div>
      </Loader>
    </>
  );
};

export default NetworkDetailsInfo;
